import React, { Component } from 'react';
import Navbar from '../components/Layouts/NavbarFour';
import Footer from '../components/Layouts/FooterTwo';

class Privacy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
              <Navbar />
              <section className="Privacy ptb-100 mt-5">
                <div className="container">
                  <h2>Pol&iacute;tica de Privacidad</h2>
                  <p>
                    <b>Alcance:</b>Esta política determina como se usan, recogen y divulgan sus datos
                    personales por parte de Neokode, también indica cómo puede acceder y actualizar sus
                    datos personales. Neokode puede recoger información de usted a través de los siguientes medios:
                  </p>
                  <ol type="a">
                    <li>Sitio web</li>
                    <li>Aplicaciones móviles</li>
                    <li>Correo electrónico</li>
                    <li>Contacto telefónico</li>
                  </ol>
                  <p>Tipo de información que se recoge del cliente</p>
                  <ol type="a">
                    <li>
                      <b>Información de contacto personal:</b> Esto incluye cualquier información que permita ponerse en
                      contacto con el cliente, tales como, su nombre; correo electrónico; redes sociales y/o número de contacto.
                    </li>
                    <li>
                      Información de Registro de Cuenta: Cualquier información que se requiera para iniciar sesión en su cuenta,
                      tales como, el alias; correo electrónico; nombre de usuario; su contraseña y/o las preguntas y respuestas
                      para recuperar su cuenta.
                    </li>
                    <li>
                      Información Demográfica: Cualquier información que indique edad o rango de edad; fecha de nacimiento;
                      sexo; ubicación geográfica; etc.
                    </li>
                    <li>
                      Información de Pagos: Cualquier información que Neokode necesite con el fin de satisfacer el servicio
                      como los detalles de tarjetas de crédito o débito y/o información de otro medio de pago si así se
                      dispone por el cliente.
                    </li>
                    <li>
                      Contacto Telefónico: Cualquier información telefónica entre ejecutivos de Neokode y el Suscriptor.
                      Estas llamadas podrían ser grabadas.
                    </li>
                    <li>
                      Información Personal Sensible: Neokode no pedirá, por ningún medio, información personal de carácter sensible.
                    </li>
                  </ol>
                  <p>
                    Con que objeto Neokode utilizará los datos personales:
                  </p>
                  <ol type="a">
                    <li>Ejecutar la relación contractual existente con sus clientes</li>
                    <li>Proveer los servicios y/o los productos requeridos por sus usuarios</li>
                    <li>Informar sobre nuevos productos o servicios y/o cambios en los mismos</li>
                    <li>Evaluar la calidad del servicio</li>
                    <li>Realizar estudios internos sobre hábitos de consumo</li>
                    <li>Soportar procesos de auditoría interna o externa</li>
                    <li>
                      Enviar al correo físico, electrónico, celular o dispositivo móvil, vía mensajes de texto (SMS y/o MMS)
                      o a través de cualquier otro medio digital para entregar información comercial, publicitaria o promocional
                      sobre los productos y/o servicios, eventos y/o promociones de tipo comercial llevados adelante por Neokode
                    </li>
                  </ol>
                  <p>
                    Los datos personales proporcionados, sólo podrán ser utilizados para los propósitos aquí señalados,
                    y por lo tanto Neokode no procederá a venderlos, licenciarlos, transferirlos, o divulgarlos, salvo que:
                  </p>
                  <ol type="a">
                    <li>Exista autorización expresa para hacerlo</li>
                    <li>Sea necesario con el fin de proveer nuestros servicios y/o productos</li>
                    <li>Sea necesario divulgar a entidades que prestan servicios de venta a Neokode</li>
                    <li>Que sea requerido o permitido por la ley</li>
                  </ol>
                  <p><b>Derechos y deberes del Titular responsable de los datos proporcionados</b></p>
                  <p>Derechos del cliente</p>
                  <ol type="a">
                    <li>
                      Conocer, actualizar y rectificar sus datos personales frente a Neokode. Este derecho se podrá ejercer,
                      entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error,
                      o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado.
                    </li>
                    <li>
                      Limitar la información que entrega a Neokode, sin perjuicio de que esto limite las facultades para utilizar
                      ciertas funcionalidades de los servicios contratados.
                    </li>
                  </ol>
                  <p>Deberes del cliente responsable</p>
                  <ol type="a">
                    <li>
                      Los datos son siempre del cliente y él es el único responsable de solicitar todos sus respaldos y
                      ejecutar el cierre de la cuenta en los servicios que hayan sido requeridos.
                    </li>
                    <li>
                      El cliente es el único administrador de los servicios y responsable de la confidencialidad de la información
                      obtenida por nuestra fuente. Neokode Ltda. se exime de responsabilidad por el mal uso de los datos en manos
                      de terceros.
                    </li>
                  </ol>
                  <p>
                    Los datos personales de los clientes serán conservados en la medida que sea necesario según los propósitos
                    indicados anteriormente o los plazos que la legislación vigente indique.
                  </p>
                  <p>
                    Cualquier cambio que se efectúe sobre la presente Política, será informado mediante correo electrónico y
                    publicado en nuestro sitio web www.neokode.cl.
                  </p>
                  <p style={{textAlign: 'center'}}><b>Esta política de privacidad fue actualizada el 30 de Julio de 2024</b></p>
                </div>
              </section>
              <Footer />
            </React.Fragment>
        );
    }
}

export default Privacy;
