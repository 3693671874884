import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <section id="about" className="about-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="about-content">
                                <div className="section-title">
                                    <h2>Nosotros</h2>
                                </div>

                                <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                      <div className="about-image">
                                          <img src={require('../../images/about.jpg')} alt="about" />
                                      </div>
                                  </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-lock"></i>
                                                </div>
                                                <h3>EXPERIENCIA</h3>
                                            </div>
                                            <p>Nuestro equipo de profesionales lleva más de una década desarrollando soluciones tecnológicas para empresas en Chile.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-desktop"></i>
                                                </div>
                                                <h3>FLEXIBILIDAD</h3>
                                            </div>
                                            <p>Nuestros servicios se adaptan a cualquier industria, negocio o cliente. Inicia con nosotros el camino hacia la transformación digital.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-lock"></i>
                                                </div>
                                                <h3>SEGURIDAD</h3>
                                            </div>
                                            <p>Utilizamos los altos estándares de seguridad de “Amazon”. Nuestros servicios cuentan con certificado digital y protección de datos.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="single-inner-content">
                                            <div className="title">
                                                <div className="icon">
                                                    <i className="fas fa-cloud"></i>
                                                </div>
                                                <h3>DISPONIBILIDAD</h3>
                                            </div>
                                            <p>Accede a nuestros servicios todos los días del año. No dudes en contactarnos; intentaremos resolver todas tus dudas.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center mt-5">
                            <div className="col-lg-2 col-md-2">
                                <div className="single-services">
                                    <div className="icon text-center">
                                        <i className="fas fa-cloud-upload-alt"></i>
                                    </div>
                                    <h3>Tecnología</h3>
                                    <p>Cloud</p>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                                <div className="single-services">
                                    <div className="icon text-center">
                                        <i className="fas fa-binoculars"></i>
                                    </div>
                                    <h3>Foco</h3>
                                    <p>Pyme</p>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                                <div className="single-services">
                                    <div className="icon text-center">
                                        <i className="fas fa-sync-alt"></i>
                                    </div>
                                    <h3>Metodología</h3>
                                    <p>Agile</p>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                                <div className="single-services">
                                    <div className="icon text-center">
                                        <i className="fas fa-handshake"></i>
                                    </div>
                                    <h3>Compromiso</h3>
                                    <p>Eficiencia</p>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                                <div className="single-services">
                                    <div className="icon text-center">
                                        <i className="fas fa-mountain"></i>
                                    </div>
                                    <h3>Desafío</h3>
                                    <p>SaaS</p>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                                <div className="single-services">
                                    <div className="icon text-center">
                                        <i className="fab fa-aws"></i>
                                    </div>
                                    <h3>Socios</h3>
                                    <p>Amazon</p>
                                </div>
                            </div>
                    </div>
                </div>

                <div className="circle"><img src={require('../../images/circle.png')} alt="cricle" /></div>
            </section>
        );
    }
}

export default About;
