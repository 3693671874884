import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

class Features extends Component {
  state = { display: false, id: "", sel: true, isOpen: false };

  componentDidMount() {
    this.setState({ display: true });
  }
  openModal = () => {
    this.setState({ isOpen: true });
  };

  render() {
    const data = [
      {
        id: "tab3",
        icon: "list-alt",
        name: "CATÁLOGOS",
        url: require("../../../images/laptopCheckList.png"),
        iconCarac: "check",
        carac1: "Ayuda interactiva",
        carac2: "Smartphones",
        carac3: "100% web",
        carac4: "Ecológica",
        descCarac1:
          "La aplicación te guiará en todo momento por si existen dudas del uso.",
        descCarac2:
          "Se adapta a cualquier tipo de pantalla, permitiendo un uso más fluido de acuerdo al dispositivo.",
        descCarac3:
          "Se puede utilizar desde cualquier dispositivo que cuente con navegador web.",
        descCarac4:
          "Amigable con el medio ambiente, elimina el uso de papel y ayuda al planeta.",
      },


    ];
    const data2 = [
      {
        id: "tab4",
        icon: "shopping-cart",
        name: "E-COMMERCE",
        url: require("../../../images/celCheck.png"),
        iconCarac: "check",
        carac1: "Cero configuraciones",
        carac2: "Acceso controlado",
        carac3: "Uso de código QR",
        carac4: "Mejora continua",
        descCarac1:
          "Listo para usar sin configuraciones, solo debes crear tus plantillas y comenzar a utilizarlo.",
        descCarac2:
          "Integrado a nuestra aplicación de administración permite un acceso seguro y por perfil de usuario.",
        descCarac3:
          "Te facilitamos el uso a través de códigos QR en tus procesos. No perderás tiempo buscando la información que necesitas.",
        descCarac4:
          "El servicio se actualiza constantemente con mejoras surgidas de nuestros propios clientes.",
      }
    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="saas-section-title">
            <h2>Características del servicio</h2>
            <div className="bar"></div>
          </div>
          <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
            <div className="container">
              {data.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                          <Link href="#">
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                this.openModal();
                              }}
                              className="video-btn popup-youtube"
                            >
                              <img src={item.url} alt="img" />
                            </a>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
            <div className="container">
              {data2.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-0 order-md-1 order-sm-1 order-1">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 order-lg-1 col-12 order-md-0 order-sm-0 order-0">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="QyDvr7tfdeU"
          onClose={() => this.setState({ isOpen: false })} />
      </section>
    );
  }
}

export default Features;
