import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

class Faq extends Component {
  render() {
    return (
      <section className="faq-area ptb-100">
        <div className="container">
          <div className="saas-section-title">
            <h2>Preguntas frecuentes</h2>
            <div className="bar"></div>
            <p>
              Si tienes alguna duda que no aparece en esta lista con gusto te
              responderemos a trav&eacute;s de nuestro formulario de contacto,
              correo electr&oacute;nico o redes sociales.
            </p>
          </div>

          <div className="faq">
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ¿Cómo puedo comenzar a utilizar el servicio?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Para comenzar debes comunicarte con nosotros a través de nuestros canales disponibles como formulario de contacto, email, RRSS o teléfono. Una vez creada la cuenta debe ir al servicio Mail y puede crear usuarios y grupos. Consulte la documentación o el botón de ayuda disponible para mayor información del uso del servicio.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ¿Qué clientes puedo usar para obtener acceso a los correos electrónicos?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Puede obtener acceso a Mail App desde clientes de Microsoft Outlook en Windows y Mac OS X, y en dispositivos móviles que admitan el protocolo Microsoft Exchange ActiveSync, incluidos iPhone, iPad, Kindle Fire, Fire Phone, Android, Windows Phone y BlackBerry 10. También se puede obtener acceso de manera segura a Mail App con el navegador web mediante la utilización de la aplicación Apple Mail en Mac OS X o de la aplicación web Amazon WorkMail.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ¿Cuál es el límite de almacenamiento del buzón de correo electrónico de Mail App?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Mail App ofrece un límite de almacenamiento en el buzón de correo de 50 GB por usuario.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ¿Cuál es el tamaño máximo de un correo electrónico enviado desde Mail App?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    El tamaño máximo de los correos salientes y entrantes en Mail App es de 25 MB.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ¿Puedo compartir mi calendario con otros usuarios de mi organización?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Sí. Mail App ofrece la posibilidad de compartir el calendario con los compañeros.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;
