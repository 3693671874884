import React, { Component } from 'react';
import { Link } from "react-router-dom";

class SaaS extends Component {
    back_url = '';
    data = {};
    constructor(props) {
        super(props)
        this.state = {
          isLoading: true,
          isError: false
        }
    }
    componentDidMount() {
      const params = new URLSearchParams(this.props.location.search);
      const saas = params.get('saas');
      this.back_url = saas;
      this.getData(saas);
    }
    getData = async (saas) => {
      const res = await fetch('/docs/' + saas + '.json')
        .then((response) => {
          return response.json();
        })
        .catch((response) => {
          this.setState({isError: true, isLoading: false});
        });
      this.setState({data: res, isLoading: false});
      this.data = JSON.parse(JSON.stringify(res));
    }
    handleSearch = (value, index) => {
      console.log(value);
      const {data} = this.state;
      const doc = data.docs[index];
      const {list} = this.data.docs[index];
      const newList = list.filter(item =>
        item.title.match(new RegExp(value, 'i'))
      );
      doc.list = newList;
      this.setState({data});
    }
    render() {
        const {data, isLoading, isError} = this.state;
        return (
            <React.Fragment>
                <div id="docs" className="docs-saas-container container">
                    <div className="d-table jumbotron">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="about-banner-content align-items-center">
                                    <h2>Documentaci&oacute;n {data && data.title}</h2>
                                    {
                                      !isLoading && !isError &&
                                      <p>
                                        {data.description}
                                      </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                      isLoading ?
                      <div className="loading">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <span className="box">&nbsp;&nbsp;Cargando contenido...</span>
                      </div>
                      :
                      <div className="doc-container">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/docs"}>Inicio</Link></li>
                            {
                              isError ?
                              <li className="breadcrumb-item active" aria-current="page">Error</li>
                              :
                              <li className="breadcrumb-item active" aria-current="page">{data.title}</li>
                            }
                          </ol>
                        </nav>
                        {
                          isError ?
                          <div className="alert alert-danger text-center">
                            No podemos mostrar la documentación en este momento, intenta más tarde o pídela a <a href="mailto:contecto@neokode.cl">contecto@neokode.cl</a>
                          </div>
                          :
                          <div className="accordion" id="docsList">
                            {
                              data.docs.map((doc, i) => {
                                const idDoc = "heading" + i;
                                return (
                                  <div key={idDoc} className="card">
                                    <div className="card-header" id={idDoc}>
                                      <h2 className="mb-0">
                                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target={"#collapse" + i} aria-expanded="true" aria-controls={"collapse" + i}>
                                          {doc.title}
                                        </button>
                                      </h2>
                                    </div>
                                    <div id={"collapse" + i} className="collapse" aria-labelledby="headingOne" data-parent="#docsList">
                                      <div className="card-body">
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">
                                              <i className="fas fa-search"></i>
                                            </span>
                                          </div>
                                          <input type="text" className="form-control" placeholder="Buscar" onChange={(evt) => { this.handleSearch(evt.target.value, i) } }/>
                                        </div>
                                        <div className="list-group">
                                          {
                                            doc.list.map((item, j) => {
                                              return (
                                                <Link key={'list' + j} to={{pathname: "/docs/detail", state: {title: item.title, url: item.url, saas: this.back_url, a: i, b: j, back: {title: data.title, url: this.back_url}}}} className="list-group-item list-group-item-action">
                                                    {item.title}
                                                </Link>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        }
                      </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default SaaS;
