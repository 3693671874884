import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='fas fa-angle-left'></i>",
        "<i class='fas fa-angle-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        768: {
            items:3,
        },
        1024: {
            items: 4,
        },
        1200: {
            items:6,
        }
    }
}

class Customers extends Component {

    state = { display:false};

    componentDidMount(){
        this.setState({ display: true })
    }

    render() {
        const partners = [
          {
            url: 'https://www.propyme.com',
            img: require("../../images/customers/propyme.png")
          },
          {
            img: require("../../images/customers/cyc.png")
          },
          {
            img: require("../../images/customers/win.png")
          },
          {
            url: 'https://www.portalcondominio.cl',
            img: require("../../images/customers/portalcondominio.png")
          },
          {
            url: 'https://www.valsol.cl',
            img: require("../../images/customers/valsol.png")
          },
          {
            url: 'https://www.pizarrovial.cl',
            img: require("../../images/customers/pizarrovial.png")
          }
        ]
        return (
            <section className="partner-area-four">
                <div className="container">
                    {/*
                      <h3>Clientes que nos prefieren</h3>
                    */}

                    <div className="row">
                        {this.state.display ? <OwlCarousel
                            className="partner-slides owl-carousel owl-theme"
                            {...options}
                        >
                            {
                              partners.map((partner, index) => {
                                return (
                                  <div key={index} className="col-lg-12 col-md-12">
                                      <div className="partner-item">
                                          <a href={partner.url} target="_blank" rel="noopener noreferrer">
                                            <img src={partner.img} alt="logo" style={{cursor: 'pointer'}}/>
                                          </a>
                                      </div>
                                  </div>
                                );
                              })
                            }
                        </OwlCarousel> : ''}
                    </div>
                </div>
            </section>
        );
    }
}

export default Customers;
