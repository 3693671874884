import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

class Features extends Component {
  state = { display: false, id: "", sel: true, isOpen: false };

  componentDidMount() {
    this.setState({ display: true });
  }
  openModal = () => {
    this.setState({ isOpen: true });
  };

  render() {
    const data = [
      {
        id: "tab3",
        icon: "list-alt",
        name: "CATÁLOGOS",
        url: require("../../../images/laptopFleet.png"),
        iconCarac: "check",
        carac1: "Gestión de flota",
        carac2: "Perfil de conductor",
        carac3: "Seguridad",
        carac4: "Ahorro de costos",
        descCarac1:
          "Administra y controla tu flota de vehículos de manera eficiente y centralizada.",
        descCarac2:
          "Generamos un perfil de manejo de cada conductor para poder comparar y poder gestionar las mejoras correspondientes.",
        descCarac3:
          "Mejora la seguridad de tus conductores y vehículos con alertas de conducción peligrosa, control de la velocidad y el comportamiento de los conductores.",
        descCarac4:
          "Optimiza tus operaciones para reducirlos costos que permitan mejorar tu rentabilidad y competitividad.",
      },
    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="saas-section-title">
            <h2>Características del servicio</h2>
            <div className="bar"></div>
          </div>
          <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
            <div className="container">
              {data.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="QyDvr7tfdeU"
          onClose={() => this.setState({ isOpen: false })}/> */}
      </section>
    );
  }
}

export default Features;
