import React, { Component } from "react";

class Advantage extends Component {
  state = { display: false, id: "", sel: true };

  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    const data = [
      {
        id: "tab3",
        icon: "list-alt",
        name: "CATÁLOGOS",
        url: require("../../../images/laptop3.png"),
        iconCarac: "check",
        carac1: "Acceso permanente",
        carac2: "Posicionamiento web",
        carac3: "Versión interactiva",
        carac4: "Diseños responsivos",
        descCarac1:
          "La web nunca se detiene y tampoco tiene límites geográficos, por lo tanto, las personas que buscan un producto podrán encontrarlo 24/7.",
        descCarac2:
          "El catálogo online aumenta tu presencia como marca en Internet. Esto ayuda a dar a conocer de forma más fácil tus productos y servicios.",
        descCarac3:
          "El cliente interactúa con el catálogo de forma amigable, lo que permite que el usuario pueda elegir lo que quiere ver durante la navegación.",
        descCarac4:
          "El contenido del sitio web se adapta de forma automática a los dispositivos móviles, no es necesario lidiar con el complejo backend.",
        ventajasTilte: "VENTAJAS DE UN CATÁLOGO",
        iconVentajas: "check",
        ventajas1: "Comercio electrónico",
        ventajas2: "Rápida actualización",
        ventajas3: "Reduce costos",
        ventajas4: "Certificado de Seguridad",
        descVentajas1:
          "Los catálogos online permiten las ventas en línea, de manera que se debe habilitar distintos medios de pago disponibles, para facilitar la venta a los clientes potenciales.",
        descVentajas2:
          "Al ser online, se pueden hacer cambios de una forma rápida y eficiente. Así, siempre estará actualizado. Esto permite cambiar precios, ofrecer descuentos, poner o quitar productos.",
        descVentajas3:
          "No tendrás necesidad de invertir en espacios físicos ni personal de servicio al cliente, con esto, lograrás disminuir considerablemente tus costos logísticos y de operaciones.",
        descVentajas4:
          "El contenido del sitio web se adapta de forma automática a los dispositivos móviles, no es necesario lidiar con el complejo backend.",
      },
      
  
    ];
    const data2 = [
      {
        id: "tab4",
        icon: "shopping-cart",
        name: "E-COMMERCE",
        url: require("../../../images/laptop3.png"),
        iconCarac: "check",
        carac1: "Funciones empresariales",
        carac2: "Certificado SSL gratis",
        carac3: "Bloques de creación",
        carac4: "Diseños responsivos",
        descCarac1:
          "Tu Comercio electrónico está integrado con otros módulos, como Servicio de asistencia o CRM.",
        descCarac2:
          "Obtén tu certificado SSL gratis de forma automática en nuestros sitios web con alojamiento en la nube.",
        descCarac3:
          "Arrastra y suelta bloques de creación con funciones empresariales. Personaliza tus bloques, desde el contenido hasta el diseño.",
        descCarac4:
          "El contenido del sitio web se adapta de forma automática a los dispositivos móviles, no es necesario lidiar con el complejo backend.",
        ventajasTilte: "VENTAJAS DE UN E-COMMERCE",
        iconVentajas: "check",
        ventajas1: "Ventas 24/7",
        ventajas2: "Mayor alcance de público",
        ventajas3: "Reduce costos",
        ventajas4: "Certificado de seguridad",
        descVentajas1:
          "Convierte tu negocio en una tienda virtual, disponible y abierta los 365 días del año, las 24 horas del día, lo que ofrece a los clientes la facilidad de compra en el horario que deseen.",
        descVentajas2:
          "Contarás con un público mucho mayor que los que podrían transitar por una tienda física. Por lo cual las posibilidades de crecimiento y el alcance son espectaculares.",
        descVentajas3:
          "No tendrás necesidad de invertir en espacios físicos ni personal de servicio al cliente, con esto, lograrás disminuir considerablemente tus costos logísticos y de operaciones.",
        descVentajas4:
          "Toda transacción o movimiento realizado en el sitio web cuentan con protección de datos y su certificado de seguridad ssl lo que garantiza que estás navegando por un sitio seguro.",
      },
    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="container">
              {data.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div class="cta-inner-content">
                        <div className="ventajas-title">
                          <h2>Ventajas del servicio</h2>
                          <div className="bar"></div>
                        </div>
                        <div class="container">
                          <div className="row">
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas1}</h3>
                                <p>{item.descVentajas1}</p>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas2}</h3>
                                <p>{item.descVentajas2}</p>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas3}</h3>
                                <p>{item.descVentajas3}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center justify-content-center pt-3">
                            <div className="container">
                              {data2.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    id={item.id}
                                    className="tabs_item"
                                  >
                                    <div className="row align-items-center">
                                      <div class="cta-inner-content">
                                        <div class="container">
                                          <div className="row">
                                            <div class="col-lg-4 col-md-6">
                                              <div class="single-cta-box">
                                                <i
                                                  className={
                                                    "fas fa-" +
                                                    item.iconVentajas
                                                  }
                                                ></i>
                                                <h3>{item.ventajas1}</h3>
                                                <p>{item.descVentajas1}</p>
                                              </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                              <div class="single-cta-box">
                                                <i
                                                  className={
                                                    "fas fa-" +
                                                    item.iconVentajas
                                                  }
                                                ></i>
                                                <h3>{item.ventajas2}</h3>
                                                <p>{item.descVentajas2}</p>
                                              </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                              <div class="single-cta-box">
                                                <i
                                                  className={
                                                    "fas fa-" +
                                                    item.iconVentajas
                                                  }
                                                ></i>
                                                <h3>{item.ventajas4}</h3>
                                                <p>{item.descVentajas4}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Advantage;
