import React, { Component } from "react";
import { Link } from "react-router-dom";

class HowWorks extends Component {
  render() {
    return (
      <section id="how-it-works" className="how-it-works ptb-100">
        <div className="container">
          <div className="payment-processing-section-title">
            <span>Fácil y rápido</span>
            <h2>Cómo trabaja</h2>
            <p>
              Implementación y configuración rápida. Completamente automatizado.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-work-process">
                <span>01</span>
                <h3>Solicita</h3>
                <p>TEXTO.</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-work-process">
                <span>02</span>
                <h3>Configuramos</h3>
                <p>TEXTO.</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
              <div className="single-work-process">
                <span>03</span>
                <h3>Úsalo</h3>
                <p>TEXTO</p>
              </div>
            </div>
          </div>

          <div className="alert-info-box">
            <Link to="/contact">
              Solicita Checklist App ahora{" "}
              <i className="fas fa-long-arrow-alt-right"></i>
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default HowWorks;
