import React, { Component } from "react";
import { Link } from "react-router-dom";

const Pricing = (props) => {
  console.log(props.id);
  console.log(props.sel);
  console.log(props);
  return (
    <section id="pricing" className="pricing-area ptb-100">
      <div className="container">
        <div className="saas-section-title">
          <h2>Nuestros precios</h2>
          <div className="bar"></div>
          <ul style={{"list-style": "none"}}>
            <li>
              Implementación desde <b>10 a 30 UF</b> + IVA
            </li>
          </ul>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6">
            <div
              className={"pricing-table active"}
            >
              <div className="pricing-header">
                <div className="icon">
                  <img src={require("../../../images/icon.png")} alt="icon" />
                </div>

                <h3>Página web estática</h3>
              </div>
              <ul className="pricing-features">
                <li className="active">Tecnología Serverless</li>
                <li className="active">Dominio .cl o .com</li>
                <li className="active">Certificado digital</li>
                <li className="active">Gran capacidad de almacenamiento</li>
                <li className="active">Google Analytics</li>
                <li className="active">Caché</li>
                <li className="active">Responsivo</li>
                <li className="active">Soporte vía email</li>
                <li className="unactive">Panel de administración </li>
                <li className="unactive">Suscripción de cliente</li>
              </ul>
              <div className="price">
                Desde 7 UF + IVA
                <span>Anual</span>
              </div>
              <div className="price">
                &nbsp;
                <span>&nbsp;</span>
              </div>
              <Link to="/contact" className="btn btn-primary">
                Consultar
              </Link>
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div
              className={
                "pricing-table" + (props.id === "tab2" ? " active" : "")
              }
            >
              <div className="pricing-header">
                <div className="icon">
                  <img src={require("../../../images/icon.png")} alt="icon" />
                </div>

                <h3>Página web administrable</h3>
              </div>
              <ul className="pricing-features">
                <li className="active">Tecnología Serverless</li>
                <li className="active">Dominio .cl o .com</li>
                <li className="active">Certificado digital</li>
                <li className="active">Gran capacidad de almacenamiento</li>
                <li className="active">Google Analytics</li>
                <li className="active">Caché</li>
                <li className="active">Responsivo</li>
                <li className="active">Soporte vía email</li>
                <li className="active">Panel de administración </li>
                <li className="active">Suscripción de cliente</li>
              </ul>
              <div className="price">
                Desde 1,5 UF + IVA
                <span>Mensual</span>
              </div>
              <div className="price">
                1.000 visitas 0,08 UF + IVA
                <span>Mensual</span>
              </div>
              <Link to="/contact" className="btn btn-primary">
                Consultar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
