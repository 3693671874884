import React from 'react'
import Navbar from '../../components/Layouts/NavbarFour';
import Footer from '../../components/Layouts/FooterTwo';
import SaaS from '../../components/Documentation/SaaS';

class DocSaas extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <Navbar/>
                <SaaS {...this.props}/>
                <Footer />
            </React.Fragment>
        );
    }
}

export default DocSaas;
