import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

class Features extends Component {
  state = { display: false, id: "", sel: true, isOpen: false };

  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    const data = [
      {
        id: "tab3",
        icon: "list-alt",
        name: "CATÁLOGOS",
        url: require("../../../images/lagosServicios.png"),
        iconCarac: "check",
        carac1: "Acceso permanente",
        carac2: "Posicionamiento web",
        carac3: "Versión interactiva",
        carac4: "Diseños responsivos",
        descCarac1:
          "La web nunca se detiene y tampoco tiene límites geográficos, por lo tanto, las personas que buscan un producto podrán encontrarlo siempre.",
        descCarac2:
          "El catálogo online aumenta tu presencia como marca en Internet. Esto ayuda a dar a conocer de forma más fácil tus productos y servicios.",
        descCarac3:
          "El cliente interactúa con el catálogo de forma amigable, lo que permite que el usuario pueda elegir lo que quiere ver durante la navegación.",
        descCarac4:
          "El contenido del sitio web se adapta de forma automática a los dispositivos móviles, no es necesario lidiar con el complejo backend.",
      },


    ];
    const data2 = [
      {
        id: "tab4",
        icon: "shopping-cart",
        name: "E-COMMERCE",
        url: require("../../../images/JuanSegura.png"),
        iconCarac: "check",
        carac1: "Funciones empresariales",
        carac2: "Certificado SSL gratis",
        carac3: "Bloques de creación",
        carac4: "Registro de clientes",
        descCarac1:
          "Tu Comercio electrónico está integrado con otros módulos, como Servicio de asistencia o CRM.",
        descCarac2:
          "Obtén tu certificado SSL gratis de forma automática en nuestros sitios web con alojamiento en la nube.",
        descCarac3:
          "Arrastra y suelta bloques de creación con funciones empresariales. Personaliza tus bloques, desde el contenido hasta el diseño.",
        descCarac4:
          "Permite crear cuentas para compras más rápidas y seguimiento de órdenes.",
      }
    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="saas-section-title">
            <h2>Características del servicio</h2>
            <div className="bar"></div>
          </div>
          <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
            <div className="container">
              {data.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
            <div className="container">
              {data2.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-0 order-md-1 order-sm-1 order-1">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 order-lg-1 col-12 order-md-0 order-sm-0 order-0">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="3Wq-ghtB0XQ"
          onClose={() => this.setState({ isOpen: false })}
        />
      </section>
    );
  }
}

export default Features;
