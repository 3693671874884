import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";

const options = {
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='fas fa-angle-left'></i>",
    "<i class='fas fa-angle-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

class Services extends Component {
  state = { display: false };

  componentDidMount() {
    this.setState({ display: true });
  }

  render() {
    return (
      <section className="cta-area-five ptb-100 bg-f5fbff">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="cta-content-five">
                <h2>CARACTERÍSTICAS</h2>
                <p>
                  Creado con la mejor tecnología existente en conjunto con
                  nuestros clientes.
                </p>

                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="box">
                      <i className="fas fa-handshake"></i>
                      <h3>Ayuda interactiva</h3>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="box">
                      <i class="fas fa-mobile-alt"></i>
                      <h3>Smartphones</h3>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="box">
                      <i class="fas fa-cloud"></i>
                      <h3>100% web</h3>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="box">
                      <i class="fas fa-lock"></i> 
                      <h3>Segura</h3>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="box">
                      <i class="fab fa-creative-commons-zero"></i>
                      <h3>Cero configuraciones</h3>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="box">
                      <i class="fas fa-user-check"></i>
                      <h3>Acceso controlado</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      // <section id="services" className="services-area ptb-100">
      //     <div className="container">
      //         <div className="saas-section-title">
      //             <h2>Caracter&iacute;sticas del servicio</h2>
      //             <div className="bar"></div>
      //             <p>Creado con la mejor tecnolog&iacute;a existente en conjunto con nuestros clientes.</p>
      //         </div>

      //         <div className="row">
      //             {this.state.display ? <OwlCarousel
      //                 className="services-slides owl-carousel owl-theme"
      //                 {...options}
      //             >
      //                 <div className="col-lg-12 col-md-12">
      //                     <div className="single-services">
      //                         <div className="icon">
      //                             <i className="fas fa-question-circle"></i>
      //                         </div>
      //                         <h3>Ayuda interactiva</h3>
      //                         <p>La aplicaci&oacute;n te guiar&aacute; en todo momento por si existen dudas del uso.</p>
      //                     </div>
      //                 </div>

      //                 <div className="col-lg-12 col-md-12">
      //                     <div className="single-services">
      //                         <div className="icon bg-cdf1d8">
      //                             <i className="fas fa-mobile-alt"></i>
      //                         </div>
      //                         <h3>Smartphones</h3>
      //                         <p>Se adapta a cualquier tipo de pantalla, permitiendo un uso m&aacute;s flu&iacute;do de acuerdo al dispositivo.</p>
      //                     </div>
      //                 </div>

      //                 <div className="col-lg-12 col-md-12">
      //                     <div className="single-services">
      //                         <div className="icon bg-f78acb">
      //                             <i className="fas fa-globe"></i>
      //                         </div>
      //                         <h3>100% web</h3>
      //                         <p>Se puede utilizar desde cualquier dispositivo que cuente con navegador web.</p>
      //                     </div>
      //                 </div>

      //                 <div className="col-lg-12 col-md-12">
      //                     <div className="single-services">
      //                         <div className="icon">
      //                             <i className="fas fa-lock"></i>
      //                         </div>
      //                         <h3>Segura</h3>
      //                         <p>Utiliza certificado SSL para una comunicaci&oacute;n segura con los servicios.</p>
      //                     </div>
      //                 </div>

      //                 <div className="col-lg-12 col-md-12">
      //                     <div className="single-services">
      //                         <div className="icon bg-c679e3">
      //                             <i className="fab fa-creative-commons-zero"></i>
      //                         </div>
      //                         <h3>Cero configuraciones</h3>
      //                         <p>Listo para usar sin configuraciones, s&oacute;lo debes crear tus plantillas y comenzar a utilizarlo.</p>
      //                     </div>
      //                 </div>

      //                 <div className="col-lg-12 col-md-12">
      //                     <div className="single-services">
      //                         <div className="icon bg-eb6b3d">
      //                             <i className="fas fa-key"></i>
      //                         </div>
      //                         <h3>Acceso Controlado</h3>
      //                         <p>Integrado a nuestra aplicaci&oacute;n de administraci&oacute;n permite un acceso seguro y por perfil de usuario.</p>
      //                     </div>
      //                 </div>

      //                 <div className="col-lg-12 col-md-12">
      //                     <div className="single-services">
      //                         <div className="icon bg-eb6b3d">
      //                             <i className="fas fa-recycle"></i>
      //                         </div>
      //                         <h3>Ecol&oacute;gica</h3>
      //                         <p>Amigable con el medio ambiente, elimina el uso de papel y ayuda al planeta.</p>
      //                     </div>
      //                 </div>
      //             </OwlCarousel> : ''}
      //         </div>
      //     </div>

      //     <div className="services-inner-area">
      //         <div className="container-fluid">
      //             <div className="row h-100 justify-content-center align-items-center">
      //                 <div className="col-lg-6 col-md-12">
      //                     <div className="services-image">
      //                         <img src={require("../../../images/checklist-2.png")} alt="service" />
      //                     </div>
      //                 </div>

      //                 <div className="col-lg-6 col-md-12">
      //                     <div className="services-inner-content">
      //                         <div className="services-item">
      //                             <div className="icon">
      //                                 <i className="fas fa-qrcode"></i>
      //                             </div>
      //                             <h3>Uso de c&oacute;digo QR</h3>
      //                             <p>Te facilitamos el uso a trav&eacute;s de c&oacute;digos QR en tus procesos. No perder&aacute;s tiempo buscando.</p>
      //                         </div>

      //                         <div className="services-item">
      //                             <div className="icon">
      //                                 <i className="fas fa-headset"></i>
      //                             </div>
      //                             <h3>Soporte</h3>
      //                             <p>El servicio incluye soporte en caso de consultas, sugerencias y reporte de errores.</p>
      //                         </div>

      //                         <div className="services-item">
      //                             <div className="icon">
      //                                 <i className="fas fa-sync"></i>
      //                             </div>
      //                             <h3>Mejora continua</h3>
      //                             <p>El servicio se actualiza constantemente con mejoras surgidas de nuestros propios clientes.</p>
      //                         </div>
      //                     </div>
      //                 </div>
      //             </div>
      //         </div>
      //     </div>
      // </section>
    );
  }
}

export default Services;
