import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Index extends Component {
    render() {
        const data = [
          {
            title: 'ERP',
            description: 'Servicio para administrar tu empresa',
            url: 'saas=erp'
          },
          {
            title: 'Correos electrónicos',
            description: 'Correos corporativos de tu empresa',
            url: 'saas=emails'
          },
          {
            title: 'E-commerce',
            description: 'Tu tienda en línea donde podrás vender lo que quieras!',
            url: 'saas=ecommerce'
          },
          {
            title: 'CheckList',
            description: 'Controla tus procesos en línea y olvida el papel',
            url: 'saas=checklist'
          },
        ];
        return (
            <React.Fragment>
                <div id="docs" className="doc-container container">
                    <div className="d-table jumbotron">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="about-banner-content align-items-center">
                                    <h2>Documentaci&oacute;n<br/>Servicios Neokode</h2>
                                    <p>
                                      Aqu&iacute; encontrar&aacute;s los manuales de nuestros servicios
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="jumbotron-fluid">
                      <div className="row">
                        {
                          data.map((doc, i) => {
                            const idDoc = "heading" + i;
                            return (
                              <div key={idDoc} className="col-sm-6 text-center">
                                <div className="card">
                                  <div className="card-body">
                                    <h5 className="card-title">{doc.title}</h5>
                                    <p className="card-text">{doc.description}</p>
                                    <Link to={"/docs/saas?" + doc.url} className="btn btn-primary">Ver documentaci&oacute;n</Link>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
