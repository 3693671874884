import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

class Features extends Component {
  state = { display: false, id: "", sel: true, isOpen: false };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  openTabSection = (evt, tabNmae) => {
    console.log(tabNmae);
    this.setState({ id: tabNmae, sel: false });
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeInUp");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeInUp animated";
    evt.currentTarget.className += "current";
  };

  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    let { id, sel } = this.state;
    const data = [
      {
        id: "tab1",
        icon: "laptop-code",
        name: "Domain",
        url: require("../../../images/laptopDomain2.png"),
        iconCarac: "check",
        carac1: "Credibilidad profesional",
        carac2: "Inversión a largo plazo",
        carac3: "Mejora de la visibilidad en línea",
        carac4: "Gestión simplificada",
        descCarac1:
          "Tu empresa tendrá una presencia en línea más profesional y confiable, lo que mejorará su reputación",
        descCarac2:
          "Estarás invirtiendo en el futuro de tu empresa, lo que te permitirá mantener tu presencia en la web a largo plazo.",
        descCarac3:
          "Un nombre de dominio personalizado y fácil de recordar puede aumentar la visibilidad de tu empresa y facilitar que tus clientes te encuentren en la web.",
        descCarac4:
          "Nuestro servicio ofrece una gestión simplificada de tus nombres de dominio, lo que te permitirá administrar y actualizar tu presencia en la web de manera fácil y eficiente.",
        sel: this.state.sel,
      },
    ];
    // const data2 = [
    //   {
    //     id: "tab2",
    //     icon: "newspaper",
    //     name: "Files",
    //     url: require("../../../images/propyme.jpg"),
    //     iconCarac: "check",
    //     carac1: "Gestión de contenidos",
    //     carac2: "Gestión De usuarios",
    //     carac3: "Fácil a la hora de aprender",
    //     carac4: "Diseño adaptable",
    //     descCarac1:
    //       "Incluimos las herramientas necesarias para editar, organizar, revisar  y publicar tus contenidos.",
    //     descCarac2:
    //       "Gestiona los roles de cada usuario, para la modificación de contenido en el CMS.",
    //     descCarac3:
    //       "Nuestra interfaz es clara y facilita la gestión y el mantenimiento de contenido.",
    //     descCarac4:
    //       "100% responsivo, es decir, adaptado para todos los dispositivos móviles.",
    //     ventajasTilte: "VENTAJAS DE UN CMS",
    //     iconVentajas: "check",
    //     ventajas1: "Edición de contenido",
    //     ventajas2: "Seguridad",
    //     ventajas3: "Cambios en tiempo real",
    //     ventajas4: "Certificado de Seguridad",
    //     descVentajas1:
    //       "Usuarios que no estén capacitados técnicamente pueden agregar, formatear y editar su contenido en el sitio web sin tener que modificar el diseño y la codificación.",
    //     descVentajas2:
    //       "Nos aseguramos de que todas las funciones de seguridad integradas del CMS estén actualizadas y sean capaces de anticipar todos los tipos de malware u otros tipos de ataques.",
    //     descVentajas3:
    //       "Al editar contenido, este podrá estar disponible para cualquier usuario de Internet desde el momento de su publicación. Esto posibilita realizar actualizaciones de forma instantánea.",
    //     descVentajas4:
    //       "Toda transaccion o movimiento realizado en el sitio web cuentan con protección de datos y su certificado de seguridad ssl.",
    //   },
    // ];
    const data3 = [
      {
        id: "tab2",
        icon: "newspaper",
        name: "Support",
        url: require("../../../images/laptopSupport.png"),
        iconCarac: "check",
        carac1: "Atención al cliente personalizada",
        carac2: "Soporte técnico especializado",
        carac3: "Resolución rápida de problemas",
        carac4: "Gestión de incidentes",
        descCarac1:
          "Disponemos de atención personalizada para cada cliente, lo que garantiza que tus necesidades específicas sean atendidas de manera efectiva.",
        descCarac2:
          "Contamos con expertos técnicos altamente capacitados, que están listos para resolver cualquier problema que puedas tener con los servicios contratados.",
        descCarac3:
          "Resolvemos los problemas de manera rápida y eficiente, minimizando el tiempo de inactividad y maximizando la productividad.",
        descCarac4:
          "Nuestro soporte a través de correo o tickets en nuestra plataforma permiten dar seguimiento a todas las incidencias reportadas",
      },
    ];
    const data4 = [
      {
        id: "tab2",
        icon: "newspaper",
        name: "Billing",
        url: require("../../../images/laptopBilling.png"),
        iconCarac: "check",
        carac1: "Medios de pago",
        carac2: "Ahorro de costos",
        carac3: "Informes y análisis",
        carac4: "Notificaciones",
        descCarac1:
          "Los pagos de tus facturas se realizan de forma automática inscribiendo tu tarjeta de débito o crédito.",
        descCarac2:
          "Con el pago automático se aprovecha mejor tu tiempo. Además, tenemos opciones de pago con descuentos.",
        descCarac3:
          "Puedes ver informes y análisis detallados sobre las facturas emitidas y pagadas, lo que te ayuda a tomar mejores decisiones",
        descCarac4:
          "Te informaremos sobre cualquier situación relacionada con costos o pagos de facturas para que puedas gestionarlas a tiempo",
      },
    ];
    const data5 = [
      {
        id: "tab5",
        icon: "newspaper",
        name: "Tratamiento de averías",
        url: require("../../../images/celTratamiento.png"),
        iconCarac: "check",
        carac1: "Acceso controlado",
        carac2: "Recopilación de datos",
        carac3: "Diagnóstico de falla",
        carac4: "Informes detallados",
        descCarac1:
          "Al contar con distintos niveles de usuarios, es posible asignar y controlar el acceso a los distintos módulos disponibles en la aplicación.",
        descCarac2:
          "Podrás realizar la recopilación e ingreso de todos los datos necesarios para realizar el proceso de diagnóstico y análisis de las posibles fallas.",
        descCarac3:
          "Durante el proceso de solución de la falla y en función a los síntomas existentes, se entregarán las alternativas de modos de falla y soluciones propuestas.",
        descCarac4:
          "Obtén información y métricas de los diferentes procesos de diagnóstico, el cual permitirá al usuario analizar los datos o características del o los síntomas existentes.",
      },
    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          {data5.map((item, index) => {
            return (
              <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100  pt-0">
                <div className="saas-section-title">
                  <h2>{item.name}</h2>
                  <div className="bar"></div>
                </div>
                <div className="container">
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 order-lg-0 order-md-0 order-sm-0 order-0">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 order-lg-1 order-md-1 order-sm-1 order-1">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {data.map((item, index) => {
            return (
              <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100  pt-0">
                <div className="saas-section-title">
                  <h2>{item.name}</h2>
                  <div className="bar"></div>
                  <ul style={{ listStyle: 'none', padding: '0px' }}>
                    <li>Servicio de <b>0.5 UF</b> + IVA ANUAL</li>
                  </ul>
                </div>
                <div className="container">
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 order-lg-3 order-md-3 order-sm-3 order-3">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 order-lg-2 order-md-2 order-sm-2 order-2">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* {data2.map((item, index) => {
            return (
              <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
                <div className="saas-section-title">
                  <h2>{item.name}</h2>
                  <div className="bar"></div>
                </div>
                <div className="container">
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-0 order-md-1 order-sm-1 order-1">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 order-lg-1 col-12 order-md-0 order-sm-0 order-0">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })} */}
          {data3.map((item, index) => {
            return (
              <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
                <div className="saas-section-title">
                  <h2>{item.name}</h2>
                  <div className="bar"></div>
                </div>
                <div className="container">
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 order-lg-4 order-md-4 order-sm-4 order-4">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 order-lg-5 order-md-5 order-sm-5 order-5">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {data4.map((item, index) => {
            return (
              <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
                <div className="saas-section-title">
                  <h2>{item.name}</h2>
                  <div className="bar"></div>
                </div>
                <div className="container">
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 order-lg-7 order-md-7 order-sm-7 order-7">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 order-lg-6 order-md-6 order-sm-6 order-6">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="3Wq-ghtB0XQ"
          onClose={() => this.setState({ isOpen: false })}
        />
      </section>
    );
  }
}

export default Features;
