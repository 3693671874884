import React, { Component } from "react";

import Navbar from "../../components/Layouts/NavbarThree";
import Banner from "../../components/Service/OtherServices/Banner";
import Features from "../../components/Service/OtherServices/Features";
import Pricing from "../../components/Service/OtherServices/Pricing";
import Contact from "../../components/Service/OtherServices/Contact";
import Footer from "../../components/Layouts/FooterTwo";

class OtherServices extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Banner />
        <section class="separator-area">&nbsp;</section>
        <Features />
        {/* <section class="separator-area">&nbsp;</section>
        <Pricing /> */}
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

export default OtherServices;
