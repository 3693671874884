import React, { Component } from 'react';
import Navbar from '../components/Layouts/Navbar';
import Footer from '../components/Layouts/Footer';
import Banner from '../components/Neokode/Banner';
import About from '../components/Neokode/About';
import Separator from '../components/Neokode/Separator';
import Saas from '../components/Neokode/Saas';
import Customers from '../components/Neokode/Customers';
import Contact from '../components/Neokode/Contact';

class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Banner />
                <Separator />
                <About />
                <Separator />
                <Saas />
                <Separator />
                <Contact />
                <Separator />
                <Customers />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Home;
