import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import Data from "./Data";

class Features extends Component {
  state = { display: false, id: "", sel: true, isOpen: false };

  render() {
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="saas-section-title">
            <h2>Características del servicio</h2>
            <div className="bar"></div>
          </div>
          <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
            <div className="container">
              {Data.feature1.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
            <div className="container">
              {Data.feature2.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-0 order-md-1 order-sm-1 order-1">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 order-lg-1 col-12 order-md-0 order-sm-0 order-0">
                        <div className="about-image">
                          <img src={item.url} alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="3Wq-ghtB0XQ"
          onClose={() => this.setState({ isOpen: false })}
        />
      </section>
    );
  }
}

export default Features;
