import React, { Component } from "react";
import { Link } from "react-router-dom";

const PricingWeb = (props) => {
  console.log(props.id);
  console.log(props.sel);
  console.log(props);
  return (
    <section id="pricing" className="pricing-area ptb-100">
      <div className="container">
        <div className="saas-section-title">
          <h2>Nuestros precios</h2>
          <div className="bar"></div>
          <ul style={{"list-style": "none"}}>
            <li>
              Implementación desde <b>15 a 30 UF</b> + IVA
            </li>
          </ul>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-6">
            <div className={
                "pricing-table active"
              }
            >
              <div className="pricing-header">
                <div className="icon">
                  <img
                    src={require("../../../images/icon.png")}
                    alt="icon"
                  />
                </div>

                <h3>Catálogo online</h3>
              </div>

              <ul className="pricing-features">
                <li className="active">Productos ilimitados</li>
                <li className="active">Panel de administración </li>
                <li className="active">Dominio .cl o .com</li>
                <li className="active">Certificado digital</li>
                <li className="active">Respaldos diarios</li>
                <li className="active">Copia de seguridad</li>
                <li className="active">Analytics</li>
                <li className="active">Responsivo</li>
                <li className="active">Soporte vía email</li>
                <li className="unactive">Registro de clientes</li>
                <li className="unactive">Suscripción de cliente</li>
                <li className="unactive">Recuperar carro de compra</li>
                <li className="unactive">Carrito abandonado</li>
                <li className="unactive">Transferencia / Webpay</li>
                <li className="unactive">Retiro en tienda / Despacho local</li>
              </ul>
              <div className="price">
                Desde De 1,5 UF + IVA
                <span>Mensual</span>
              </div>
              <Link to="/contact" className="btn btn-primary">
                Consultar
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className={
                "pricing-table" +
                (props.id === "tab4" ? " active" : "")
              }
            >
              <div className="pricing-header">
                <div className="icon">
                  <img
                    src={require("../../../images/icon.png")}
                    alt="icon"
                  />
                </div>

                <h3>E-commerce</h3>
              </div>

              <ul className="pricing-features">
                <li className="active">Productos ilimitados</li>
                <li className="active">Panel de administración </li>
                <li className="active">Dominio .cl o .com</li>
                <li className="active">Certificado digital</li>
                <li className="active">Respaldos diarios</li>
                <li className="active">Copia de seguridad</li>
                <li className="active">Analytics</li>
                <li className="active">Responsivo</li>
                <li className="active">Soporte vía email</li>
                <li className="active">Registro de clientes</li>
                <li className="active">Suscripción de cliente</li>
                <li className="active">Recuperar carro de compra</li>
                <li className="active">Carrito abandonado</li>
                <li className="active">Transferencia / Webpay</li>
                <li className="active">Retiro en tienda / Despacho local</li>
              </ul>
              <div className="price">
                Desde 1 a 2,5 UF + IVA
                <span>Mensual</span>
              </div>
              <Link to="/contact" className="btn btn-primary">
                Consultar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingWeb;
