import React, { Component } from "react";

import Navbar from "../../components/Layouts/NavbarThree";
import Footer from "../../components/Layouts/FooterTwo";
import Banner from "../../components/Service/Iot/Banner";
import Features from "../../components/Service/Iot/Features";
import Advantage from "../../components/Service/Iot/Advantage";
import Contact from "../../components/Service/Iot/Contact";


class IoTApp extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Banner />
        <section class="separator-area">&nbsp;</section>
        {/* <section class="separator-area">&nbsp;</section> */}
        <Features />
        <section class="separator-area">&nbsp;</section>
        <Advantage />
        <Contact />
        <Footer />
      </React.Fragment> 
    );
  }
}

export default IoTApp;
