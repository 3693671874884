import React, { Component } from "react";

import Navbar from "../../components/Layouts/NavbarThree";
import Footer from "../../components/Layouts/FooterTwo";
import Banner from "../../components/Service/Fleet/Banner";
import Features from "../../components/Service/Fleet/Features";
import Advantage from "../../components/Service/Fleet/Advantage";
import Pricing from "../../components/Service/Fleet/Pricing";
import Faq from "../../components/Service/Fleet/Faq";
import Contact from "../../components/Service/Fleet/Contact";


class FleetApp extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Banner />
        <section class="separator-area">&nbsp;</section>
        <Features />
        <section class="separator-area">&nbsp;</section>
        <Advantage />
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

export default FleetApp;
