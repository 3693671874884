import React, { Component } from "react";
import { Link } from "react-router-dom";

class Saas extends Component {
  render() {
    return (
      <section id="saas" className="features-area ptb-100 bg-f9f9f9">
        <div className="container">
          <div className="section-title">
            <h2>servicios</h2>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6">
              <Link to="/services/erp">
                <div className="single-features">
                  <div className="icon bg5">
                    <i className="fas fa-industry"></i>
                  </div>
                  <h3>ERP</h3>
                  <p>
                    Gestiona tus ventas, compras, inventario, RR.HH.,
                    contabilidad en ERP.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to="/services/web">
                <div className="single-features">
                  <div className="icon bg1">
                    <i className="fas fa-globe-americas"></i>
                  </div>
                  <h3>WEB</h3>
                  <p>
                    Diseñamos, desarrollamos y mantenemos tus p&aacute;ginas Web est&aacute;ticas o autoadministrables.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to="/services/ecommerce">
                <div className="single-features">
                  <div className="icon bg5">
                  <i class="fas fa-cart-plus"></i>
                  </div>
                  <h3>E-COMMERCE</h3>
                  <p>
                    Gestiona tu negocio a trav&eacute;s de nuestro carrito de compras o cat&aacute;logo online.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to="/services/mail">
                <div className="single-features">
                  <div className="icon bg1">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <h3>MAIL</h3>
                  <p>
                    Utiliza Workmail para tu correo electr&oacute;nico y calendario
                    empresarial.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to="/services/checklist">
                <div className="single-features">
                  <div className="icon bg2">
                    <i className="fas fa-tasks"></i>
                  </div>
                  <h3>CHECKLIST</h3>
                  <p>
                    Crea formularios digitales, registra actividades cr&iacute;ticas de
                    tu proceso y analizar los datos.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to="/services/fleet">
                <div className="single-features">
                  <div className="icon bg3">
                    <i className="fas fa-bus"></i>
                  </div>
                  <h3>FLEET</h3>
                  <p>
                    Controla tu flota de veh&iacute;culos de manera centralizada,
                    confiable y segura.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <Link to="/services/iot">
                <div className="single-features">
                  <div className="icon bg4">
                    <i className="fas fa-chart-line"></i>
                  </div>
                  <h3>IOT</h3>
                  <p>
                    Obtiene informaci&oacute;n, administra datos y analiza predictivamente los activo de tu empresa.
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
            <Link to="/services/masaplicaciones">
            <div className="single-features">
                <div className="icon bg6">
                  <i className="fas fa-ellipsis-h"></i>
                </div>
                <h3>M&aacute;s aplicaciones</h3>
                <p>
                  Constantemente estamos desarrollando nuevos servicios para
                  mejorar la calidad de vida de nuestros clientes.
                </p>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Saas;
