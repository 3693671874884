import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Data from "./Data";

Modal.setAppElement("#root");
const customStyles = {
  overlay: { zIndex: 102, backgroundColor: "rgba(0, 0, 0, 0.55)" },
};

class Features extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      item: {},
    };
  }
  handleClick = (item) => {
    this.setState({ showModal: true, item: item });
  };
  handleCloseModal = (item) => {
    this.setState({ showModal: false });
  };
  renderModal() {
    const { item } = this.state;
    return (
      <Modal
        isOpen={this.state.showModal}
        contentLabel="ERP"
        style={customStyles}
      >
        <div className="saas-section-title">
          <h2>{item.name}</h2>
          <div className="bar"></div>
          <p>{item.desc}</p>
        </div>
        <div className="row feature-detail">
          <div className="col-md-6">
            <img src={item.url} alt="ERP" />
          </div>
          <div className="col-md-6">
            <div className="features-content">
              {item.features && (
                <div className="row">
                  {item.features.map((feature, index) => {
                    return (
                      <div className="col-md-12">
                        <div className="box" style={{ marginTop: "15px" }}>
                          <i className={"fas fa-" + feature.icon}></i>{" "}
                          {feature.text}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="saas-section-title">
          <button
            className="btn btn-primary"
            style={{ position: "absolute" }}
            onClick={this.handleCloseModal}
          >
            Cerrar
          </button>
        </div>
      </Modal>
    );
  }
  render() {
    return (
      <React.Fragment>
        <section id="features" className="features ptb-100 bg-f5fbff">
          <div className="container">
            <div className="erp-section-title">
              <span>Versatilidad</span>
              <h2>Soluciones para todos los rubros</h2>
              <p>
                Tu empresa es única y lo entendemos así. Por eso el ERP se
                adapta a cualquier necesidad integrando funcionalidades.
              </p>
            </div>

            <div className="row">
              {Data.feature.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-lg-3 col-md-6"
                    onClick={() => {
                      this.handleClick(item);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="single-features-box">
                      <i className={"fas fa-" + item.icon}></i>
                      <h3>{item.name}</h3>
                    </div>
                  </div>
                );
              })}
              {/* <div className="col-lg-12 col-md-12">
                <div className="single-features-box">
                  <i className="fas fa-question"></i>
                  <h3>&iquest;Tienes otro rubro? Consúltanos</h3>
                </div>
              </div> */}
            </div>
            {/* <div className="start-now-content">
              <Link to="/contact" className="btn btn-primary">
                Comienza ahora
              </Link>
            </div> */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
