import React, { Component } from "react";
import Typist from "react-typist";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import { Link } from "react-router-dom";
import Contact from "./Contact";

class Banner extends Component {
  state = {
    isOpen: false,
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  render() {
    return (
      <React.Fragment>
        <div
          id="home"
          className="main-banner jarallax"
          data-jarallax='{"speed": 0.2}'
        >
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="main-banner-content">
                  <h1>
                    <b>Plataforma para PYMES</b>
                  </h1>
                  <Typist
                    startDelay={500}
                    avgTypingDelay={50}
                    cursor={{
                      show: true,
                      blink: true,
                      element: "",
                      hideWhenDone: true,
                      hideWhenDoneDelay: 500,
                    }}
                  >
                    <p>
                      <span>Aplicaciones <b>imprescindibles</b> para tu negocio.</span>
                    </p>
                  </Typist>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          id="home"
          className="lead-generation-banner jarallax"
          data-jarallax='{"speed": 0.2}'
        >
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-7 col-md-12">
                    <div className="lead-generation-banner-content">
                      <h1>
                        GESTIONA TU PYME DIGITALMENTE
                      </h1>
                      <h1>
                        TODO EN UNA SOLA PLATAFORMA
                      </h1>
                      <h1>
                        ALTOS ESTANDARES DE SEGURIDAD
                      </h1>
                       <Typist startDelay={500} avgTypingDelay={50} cursor={{show: true, blink: true, element: '', hideWhenDone: true, hideWhenDoneDelay: 500}}>
                                      <p>
                                        <span>Disminuimos tus costos, controlamos tus riesgos y mejoramos tu experiencia</span>
                                      </p>
                                    </Typist> 

                      <ul>
                        <li>Mail</li>
                        <li>Paginas web, CMS, Ecommerce</li>
                        <li>Erp</li>
                        <li>Checklist</li>
                        <li>Fleet</li>
                        <li>Iot</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-5 col-md-12">
                    <div className="lead-generation-form">
                      <div className="form-header">
                        <h3>Contáctanos</h3>
                        <span>para más información</span>
                      </div>

                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Nombre"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Email"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Asunto"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Tel&eacute;fono"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <textarea
                            type="text"
                            placeholder="Mensaje"
                            className="form-control"
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Enviar Mensaje
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  */}
      </React.Fragment>
    );
  }
}

export default Banner;
