import React, { Component } from "react";
import Data from "./Data";

class Cta extends Component {
  render() {
    return (
      <section className="cta-area-five ptb-100 bg-f5fbff">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="cta-content-five">
                <div className="erp-section-title">
                  <span>Completamente integrado</span>
                  <h2>Todo en un solo lugar</h2>
                  <p>
                    Nuestro ERP evoluciona rápido con una experiencia de usuario de primera categoría. Un módulo
                    para cada área de la empresa.
                  </p>
                </div>
                <div class="row">
                  {Data.cta.map((item, index) => {
                    return (
                      <div className="col-lg-2 col-md-6">
                        <div className="box">
                          <i className={item.icon}></i>
                          <h3>{item.title}</h3>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Cta;
