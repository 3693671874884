export default {
  cta: [
    {
      icon: 'fas fa-handshake',
      title: 'CRM',
    },
    {
      icon: 'fas fa-store-alt',
      title: 'POS',
    },
    {
      icon: 'fas fa-chart-line',
      title: 'Ventas',
    },
    {
      icon: 'fas fa-box-open',
      title: 'Inventario',
    },
    {
      icon: 'fas fa-wrench',
      title: 'Manufactura',
    },
    {
      icon: 'fas fa-credit-card',
      title: 'Compra',
    },
    {
      icon: 'fas fa-file-invoice-dollar',
      title: 'Facturación',
    },
    {
      icon: 'fas fa-file-invoice',
      title: 'Contabilidad',
    },
    {
      icon: 'fas fa-money-check-alt',
      title: 'Gastos',
    },
    {
      icon: 'fas fa-puzzle-piece',
      title: 'Proyecto',
    },
    {
      icon: 'fas fa-stopwatch',
      title: 'Horas',
    },
    {
      icon: 'fas fa-network-wired',
      title: 'Más',
    },
  ],

  feature: [
    {
      id: "tab1",
      icon: "cash-register",
      name: "Comercializadora",
      url: require("../../../images/erp-business-1.png"),
      desc: "Solución diseñada para negocios que realicen ventas directas y deseen llevar un control instantáneo de todos los movimientos que se realicen.",
      features: [
        { icon: "box", text: "Vende online" },
        { icon: "box", text: "Vende directamente desde el sistema" },
        { icon: "box", text: "Controla tu stock" },
        { icon: "box", text: "Define tus flujos de venta" },
        { icon: "box", text: "Controla tus costos" },
        { icon: "box", text: "Aplica descuentos" },
        { icon: "box", text: "Integrada con el SII" },
        { icon: "box", text: "Se adapta a tu negocio" },
      ],
      sel: true,
    },
    {
      id: "tab2",
      icon: "industry",
      name: "Manufactura",
      url: require("../../../images/erp-business-2.png"),
      desc: "Solución diseñada para empresas manufactureras permitiendo el control de toda la operación de fabricación.",
      features: [
        { icon: "box", text: "Gestión de stock" },
        { icon: "box", text: "Gestión de órdenes de manufactura" },
        { icon: "box", text: "Gestión de órdenes de trabajo " },
        { icon: "box", text: "Gestión de materiales y mano de obra" },
        { icon: "box", text: "Centros de producción" },
        { icon: "box", text: "Rutas de producción" },
        { icon: "box", text: "Desechos" },
        { icon: "box", text: "Trazabilidad de materia prima y productos" },
        { icon: "box", text: "Gestión de maquinarias" },
      ],
    },
    {
      id: "tab3",
      icon: "users",
      name: "Servicios",
      url: require("../../../images/erp-business-3.png"),
      desc: "Solución dirigida a empresas de servicios que quieran administrar su negocio.",
      features: [
        { icon: "box", text: "Gestiona tus gastos" },
        { icon: "box", text: "Gestiona tus compras" },
        { icon: "box", text: "Administra tus proyectos" },
        { icon: "box", text: "Gestiona tus clientes" },
      ],
    },
    {
      id: "tab4",
      icon: "calendar-alt",
      name: "Eventos",
      url: require("../../../images/erp-business-4.png"),
      desc: "Solución dirigda a empresa que organizan eventos.",
      features: [
        { icon: "box", text: "Controla tus costos" },
        { icon: "box", text: "Administra tus eventos" },
        { icon: "box", text: "Cotiza a diferentes proveedores" },
        {
          icon: "box",
          text: "Genera presupuestos a tus clientes fácilmente",
        },
      ],
    },
    {
      id: "tab5",
      icon: "bus",
      name: "Transporte",
      url: require("../../../images/erp-business-5.png"),
      desc: "Solución dirigda a empresa que administran una flota de vehículos.",
      features: [
        { icon: "box", text: "Controla el combustible" },
        {
          icon: "box",
          text: "Gestiona los mantenimientos por kilometraje y por horas",
        },
        { icon: "box", text: "Gestiona a conductores" },
        { icon: "box", text: "Administra tus servicios" },
        { icon: "box", text: "Controla tus costos" },
        {
          icon: "box",
          text: "Admiinistra la documentación de cada vehículo",
        },
      ],
    },
    {
      id: "tab6",
      icon: "wrench",
      name: "Taller mecánico",
      url: require("../../../images/erp-business-6.png"),
      desc: "Solución para talleres mecánicos.",
      features: [
        { icon: "box", text: "Gestiona los repuestos" },
        { icon: "box", text: "Controla tus compras" },
        { icon: "box", text: "Gestiona las órdenes de trabajo" },
        { icon: "box", text: "Controla la mano de obra" },
        { icon: "box", text: "Controla el uso de repuestos y materiales" },
        { icon: "box", text: "Gestiona comisiones para los mecánicos" },
      ],
    },
    {
      id: "tab7",
      icon: "graduation-cap",
      name: "Colegio",
      url: require("../../../images/erp-business-7.png"),
      desc: "Solución completa para instituciones educativas.",
      features: [
        { icon: "box", text: "Administra tu año escolar" },
        { icon: "box", text: "Gestiona el proceso de admisión de alumnos" },
        {
          icon: "box",
          text: "Gestiona las salas de clases, niveles, asignaturas",
        },
        { icon: "box", text: "Administra alumnos, profesores, apoderados" },
        { icon: "box", text: "Administra notas, horarios, asistencia" },
        { icon: "box", text: "Administra eventos" },
        { icon: "box", text: "Gestiona pagos de arancel" },
      ],
    },
    {
      id: "tab8",
      icon: "clinic-medical",
      name: "Hospital/Clínica",
      url: require("../../../images/erp-business-8.png"),
      desc: "Solución destinada a centros médicos, hospitales, clínicas.",
      features: [
        { icon: "box", text: "Administra las fichas de tus pacientes" },
        { icon: "box", text: "Entrega presupuestos" },
        { icon: "box", text: "Administra las citas" },
        { icon: "box", text: "Controla preexistencias" },
        { icon: "box", text: "Gestiona las hospitalizaciones" },
        { icon: "box", text: "Gestiona las recetas médicas" },
        { icon: "box", text: "Administra el laboratorio" },
        { icon: "box", text: "Gestiona familiares" },
        { icon: "box", text: "Accede al historial del paciente" },
        { icon: "box", text: "Usa la herramienta de apoyo de síntomas" },
        { icon: "box", text: "Gestiona los nacimientos" },
        { icon: "box", text: "Administra los pagos" },
      ],
    },
    {
      id: "tab9",
      icon: "concierge-bell",
      name: "Hotelería",
      url: require("../../../images/erp-business-9.png"),
      desc: "Solución para hoteles, residenciales y otro servicio de alojamiento.",
      features: [
        { icon: "box", text: "Administra las reservas" },
        { icon: "box", text: "Gestionar el ingreso y salida" },
        { icon: "box", text: "Administra a tus huéspedes" },
        { icon: "box", text: "Gestiona el Restaurant" },
        { icon: "box", text: "Administra y supervisa la limpieza" },
        { icon: "box", text: "Administra los eventos" },
      ],
    },
    {
      id: "tab10",
      icon: "grin-hearts",
      name: "Salón de belleza",
      url: require("../../../images/erp-business-11.png"),
      desc: "Solución para administrar un salón de belleza.",
      features: [
        { icon: "box", text: "Gestiona reserva de sillas" },
        { icon: "box", text: "Administra los procedimientos" },
        { icon: "box", text: "Controla los materiales" },
        { icon: "box", text: "Controla la mano de obra" },
        { icon: "box", text: "Gestiona el tiempo de atención" },
        { icon: "box", text: "Administra los clientes" },
      ],
    },
    {
      id: "tab11",
      icon: "utensils",
      name: "Restaurant",
      url: require("../../../images/erp-business-12.gif"),
      desc: "Solución para Restaurantes.",
      features: [
        { icon: "box", text: "Administra las mesas" },
        { icon: "box", text: "Gestiona los pedidos por mesa" },
        { icon: "box", text: "..." },
      ],
    },
    {
      id: "tab12",
      icon: "building",
      name: "Corretaje de propiedades",
      url: require("../../../images/erp-business-10.png"),
      desc: "Solución para empresas que vendan o arrienden propiedades.",
      features: [
        { icon: "box", text: "Gestiona visitas" },
        { icon: "box", text: "Administra tus propiedades" },
        { icon: "box", text: "Gestiona tus clientes" },
        { icon: "box", text: "Controla los pagos" },
      ],
    },
  ],

  howWorks: [
    {
      span: "01",
      title: "Solicita el ERP",
      desc: "Cuéntanos de tu negocio y tus necesidades.",
    },
    {
      span: "02",
      title: "Configuramos el ERP",
      desc: "Configuramos el sistema de acuerdo a tu necesidad de negocio.",
    },
    {
      span: "03",
      title: "Úsalo",
      desc: "Te capacitamos en el uso del sistema. Es amigable e intuitivo.",
    },
  ],

  pricing : [
    {
      active: '',
      title: "Plan Emprende",
      pricing_features: [
        {
          li: "Ventas hasta 600 UF"
        },
        {
          li: "Hasta 3 usuarios"
        },
        {
          li: "Ventas",
        },
        {
          li: "Compras",
        },
        {
          li: "Inventario",
        },
        {
          li: "Gastos",
        },
        {
          li: "Contabilidad",
        },
        {
          li: "Cobranza",
        },
        {
          li: "Punto de venta",
        },
        {
          li: "Alertas",
        },
        {
          li: "Reportes",
        },
        {
          li: "Soporte",
        },
        {
          li: "Facturación electrónica",
        },
        {
          li: '&nbsp;',
        },
        {
          li: '&nbsp;',
        },
        {
          li: '&nbsp;',
        },
         {
          li: '&nbsp;',
        },
      ],
      price: "Desde 3 UF + IVA"
    },
    {
      active: 'active',
      title: "Plan PYME",
      pricing_features: [
        {
          li: "Ventas hasta 5.000 UF"
        },
        {
          li: "Hasta 7 usuarios"
        },
        {
          li: "CRM",
        },
        {
          li: "Ventas",
        },
        {
          li: "Compras",
        },
        {
          li: "Inventario",
        },
        {
          li: "Gastos",
        },
        {
          li: "Contabilidad",
        },
        {
          li: "Cobranza",
        },
        {
          li: "Punto de venta",
        },
        {
          li: "Alertas",
        },
        {
          li: "Reportes",
        },
        {
          li: "Soporte",
        },
        {
          li: "Facturación electrónica",
        },
        {
          li: 'Módulos personalizados',
        },
        {
          li: '&nbsp;',
        },
         {
          li: '&nbsp;',
        },
      ],
      price: "Desde 7 UF + IVA"
    },
    {
      active: '',
      title: "Plan Estándar",
      pricing_features: [
        {
          li: "Ventas hasta 25.000 UF"
        },
        {
          li: "Hasta 12 usuarios"
        },
        {
          li: "CRM",
        },
        {
          li: "Ventas",
        },
        {
          li: "Compras",
        },
        {
          li: "Inventario",
        },
        {
          li: "Gastos",
        },
        {
          li: "Contabilidad",
        },
        {
          li: "Cobranza",
        },
        {
          li: "Punto de venta",
        },
        {
          li: 'RRHH',
        },
        {
          li: 'Manufactura',
        },
        {
          li: "Alertas",
        },
        {
          li: "Reportes",
        },
        {
          li: "Soporte",
        },
        {
          li: "Facturación electrónica",
        },
        {
          li: 'Módulos personalizados',
        },
      ],
      price: "Desde 12 UF + IVA"
    },
    {
      active: '',
      title: "Plan Premium",
      pricing_features: [
        {
          li: "Ventas desde 25.000 UF"
        },
        {
          li: "Hasta 20 usuarios"
        },
        {
          li: "CRM",
        },
        {
          li: "Ventas",
        },
        {
          li: "Compras",
        },
        {
          li: "Inventario",
        },
        {
          li: "Gastos",
        },
        {
          li: "Contabilidad",
        },
        {
          li: "Cobranza",
        },
        {
          li: "Punto de venta",
        },
        {
          li: 'RRHH y remuneraciones',
        },
        {
          li: 'Manufactura',
        },
        {
          li: "Alertas",
        },
        {
          li: "Reportes",
        },
        {
          li: "Soporte",
        },
        {
          li: "Facturación electrónica",
        },
        {
          li: 'Módulos personalizados',
        },
      ],
      price: "Desde 20 UF + IVA"
    },
  ],
  table : [
    {
      desde: "1",
      hasta: "100",
      precio: "0,7",
      tipo: "Mensual",
    },
    {
      desde: "101",
      hasta: "400",
      precio: "1,0",
      tipo: "Mensual",
    },
    {
      desde: "401",
      hasta: "750",
      precio: "1,3",
      tipo: "Mensual",
    },
    {
      desde: "751",
      hasta: "1.000",
      precio: "1,6",
      tipo: "Mensual",
    },
    {
      desde: "1.001",
      hasta: "1.500",
      precio: "2,0",
      tipo: "Mensual",
    },
    {
      desde: "1.501",
      hasta: "2.000",
      precio: "2,4",
      tipo: "Mensual",
    },
    {
      desde: "2.001",
      hasta: "3.000",
      precio: "2,7",
      tipo: "Mensual",
    },
    {
      desde: "3.001",
      hasta: "4.000",
      precio: "3,4",
      tipo: "Mensual",
    },
    {
      desde: "4.001",
      hasta: "5.000",
      precio: "3,9",
      tipo: "Mensual",
    },
    {
      desde: "5.001",
      hasta: "7.000",
      precio: "4,3",
      tipo: "Mensual",
    },
    {
      desde: "7.001",
      hasta: "10.000",
      precio: "5,9",
      tipo: "Mensual",
    },
  ]
}
