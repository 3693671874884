import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Data from './Data';

class HowWorks extends Component {
  render() {
    return (
      <section id="how-it-works" className="how-it-works ptb-100">
        <div className="container">
          <div className="payment-processing-section-title">
            <span>Fácil y rápido</span>
            <h2>Cómo trabaja</h2>
            <p>Implementación y configuración rápida. Completamente automatizado.</p>
          </div>
          <div class="row">
            {Data.howWorks.map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6">
                  <div className="single-work-process">
                    <span>{item.span}</span>
                    <h3>{item.title}</h3>
                    <p>{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="alert-info-box">
            <Link to="/contact">Solicita tu ERP ahora <i className="fas fa-long-arrow-alt-right"></i></Link>
          </div>
        </div>
      </section>
    );
  }
}

export default HowWorks;
