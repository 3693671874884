const local = {
  ga_analytics: ' ',
  ReCAPTCHA: '6Ld3VcAUAAAAANPcrA833SD5aUCZLFwEFabNNj0X',
  url: {
    api: "https://api.neokodelabs.com/web",
    admin: "http://localhost:3001",
    support: "http://localhost:3000",
  }
};

const dev = {
  ga_analytics: ' ',
  ReCAPTCHA: '6Ld3VcAUAAAAANPcrA833SD5aUCZLFwEFabNNj0X',
  url: {
    api: "https://api.neokodelabs.com/web",
    admin: "https://admin.neokodelabs.com",
    support: "https://support.neokodelabs.com"
  }
};

const prod = {
  ga_analytics: 'G-NF5ZX9GP61',
  ReCAPTCHA: '6LdKVcAUAAAAAB6fwfBE1oZPQyV4k7bktS90aCyj',
  url: {
    api: "https://api.neokode.cl/web",
    admin: "https://admin.neokode.cl",
    support: "https://support.neokode.cl"
  }
};

const config = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() === 'production'
  ? prod
  : process.env.REACT_APP_ENV === 'development'
  ? dev
  : local;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
