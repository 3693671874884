import React from 'react'
import NavbarSix from '../components/Layouts/NavbarSix';
import Footer from '../components/Layouts/FooterTwo';
import Contact from '../components/Neokode/Contact';

class ContactUs extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-title-area jarallax">
                  <div className="container">
                    <h2>Cu&eacute;ntanos, &iquest;c&oacute;mo te ayudamos?</h2>
                  </div>
                </div>
                <NavbarSix/>
                <Contact noTitle/>
                <Footer />
            </React.Fragment>
        );
    }
}

export default ContactUs;
