import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import NotFound from './pages/NotFound';
// Services
import ErpApp from './pages/services/ErpApp';
import WebApp from './pages/services/WebApp';
import EcommerceApp from './pages/services/EcommerceApp';
import MailApp from './pages/services/MailApp';
import ChecklistApp from './pages/services/ChecklistApp';
import FleetApp from './pages/services/FleetApp';
import IotApp from './pages/services/IoTApp';
import OtherServices from "./pages/services/OtherServices";
import ContactUs from './pages/ContactUs';

//Docs
import Documentation from './pages/Documentation';
import DocumentationDetail from './pages/docs/DocDetail';
import DocumentationSaaS from './pages/docs/DocSaaS';


export default ({ childProps }) =>
  <Switch>
    <Route path="/" exact component={Home} props={childProps} />
    <Route path="/home" exact component={Home} props={childProps} />
    <Route path="/contact" exact component={ContactUs} props={childProps} />
    <Route path="/privacy" exact component={Privacy} props={childProps} />
    <Route path="/terms" exact component={Terms} props={childProps} />
    { /* Services */ }
    <Route path="/services/erp" exact component={ErpApp} props={childProps} />
    <Route path="/services/web" exact component={WebApp} props={childProps} />
    <Route path="/services/ecommerce" exact component={EcommerceApp} props={childProps} />
    <Route path="/services/mail" exact component={MailApp} props={childProps} />
    <Route path="/services/checklist" exact component={ChecklistApp} props={childProps} />
    <Route path="/services/fleet" exact component={FleetApp} props={childProps} />
    <Route path="/services/iot" exact component={IotApp} props={childProps} />
    <Route path="/services/masaplicaciones" exact component={OtherServices} props={childProps} />

    <Route path="/docs" exact component={Documentation} props={childProps} />
    <Route path="/docs/detail" exact component={DocumentationDetail} props={childProps} />
    <Route path="/docs/saas" exact component={DocumentationSaaS} props={childProps} />

    <Route component={NotFound} />
  </Switch>;
