import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Pricing extends Component {
    render() {
        return (
            <section id="pricing" className="pricing-area ptb-100">
                <div className="container">
                    <div className="saas-section-title">
                        <h2>Nuestros precios</h2>
                        <div className="bar"></div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="pricing-table active">
                            <div className="pricing-header">
                                <div className="icon">
                                    <img src={require("../../../images/icon.png")} alt="icon" />
                                </div>
                                <h3>SERVICIO EMAIL EMPRESARIAL</h3>
                            </div>

                            <ul className="pricing-features">
                                <li className="active">Casilla empresarial</li>
                                <li className="active">Calendario</li>
                                <li className="active">Tipo Exchange</li>
                                <li className="active">50 GB Almacenamiento</li>
                                <li className="active">Compatible con Outlook</li>
                                <li className="active">Compatible con dispositivos móviles</li>
                                <li className="active">Compatibilidad del protocolo IMAP</li>
                                <li className="active">Cliente web</li>
                                <li className="active">Reglas del flujo</li>
                                <li className="active">Antispam y antivirus</li>
                            </ul>

                            <div className="price">
                                0.15 UF + IVA
                                <span>Casilla / mensual</span>
                            </div>
                            <Link to="/contact" className="btn btn-primary">Consultar</Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Pricing;
