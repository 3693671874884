import React, { Component } from "react";

class Advantage extends Component {
  state = { display: false, id: "", sel: true };

  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    const data = [
      {
        id: "tab3",
        iconVentajas: "check",
        ventajas1: "Análisis de datos en tiempo real",
        ventajas2: "Gestiona solo las excepciones",
        ventajas3: "Reportes de gestión",
        descVentajas1:
          "Obtén información valiosa sobre tus operaciones y el rendimiento de tus vehículos para tomar decisiones más informadas y mejorar tus procesos.",
        descVentajas2:
          "Preocúpate solo de lo que está fuera de rango configurado y optimiza tiempos de trabajo, el resto funciona automáticamente.",
        descVentajas3:
          "Obtiene informes que te ayudarán a la toma de decisiones de manera flexible y rápido.",
      },


    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="container">
              {data.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div class="cta-inner-content">
                        <div className="ventajas-title">
                          <h2>Ventajas del servicio</h2>
                          <div className="bar"></div>
                        </div>
                        <div class="container">
                          <div className="row">
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas1}</h3>
                                <p>{item.descVentajas1}</p>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas2}</h3>
                                <p>{item.descVentajas2}</p>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas3}</h3>
                                <p>{item.descVentajas3}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Advantage;
