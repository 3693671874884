import React, { Component } from "react";
import { Link } from "react-router-dom";

class Contact extends Component {
  render() {
    return (
      <section id="contact" className="erp-contact">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="contact-content-box">
                <h3>&iquest;Deseas realizar una cotización?</h3>
                <p>Solicita tus servicios ahora.</p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="contact-connect">
                <Link to="/contact" className="talk-to-sales">
                  Contactanos
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;