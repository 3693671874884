import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "../../../../node_modules/react-modal-video/scss/modal-video.scss";

class Banner extends Component {
  state = {
    isOpen: false,
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };
  render() {
    return (
      <React.Fragment>
        <div id="home" className="payment-processing-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12 p-0">
                <div className="payment-processing-banner-content">
                  <h1>Servicio Fleet</h1>
                  <p>
                    Tecnología de última generación para administrar tu flota de manera eficiente y efectiva.
                  </p>
                  <div className="btn-box">
                    {/* <a href="#pricing" className="btn btn-primary">
                      Ver los precios
                    </a> */}
                    <Link to="/contact">
                      <a className="btn btn-primary">Contrata ahora</a>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 p-0">
                <div className="payment-processing-banner-image fleet">
                  <img
                    src={require("../../../images/banFleet.png")}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Banner;
