import React, { Component } from "react";
import { Link } from "react-router-dom";

class Banner extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="home" className="payment-processing-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12 p-0">
                <div className="payment-processing-banner-content">
                  <h1>Servicio E-commerce</h1>
                  <p>
                    Tenemos disponible el servicio de e-commerce donde puedes
                    sacarle partido a tu negocio y ofrecer tus productos y
                    servicios sin barreras geográficas.
                  </p>
                  <div className="btn-box">
                    <a href="#pricing" className="btn btn-primary">
                      Ver los precios
                    </a>
                    <Link to="/contact">
                      <a className="btn btn-primary">Contrata ahora</a>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 p-0">
                <div className="payment-processing-banner-image ecommerce">
                  <img
                    src={require("../../../images/banEcommerce.png")}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Banner;
