import React from 'react';
import FloatingWhatsApp from "react-floating-whatsapp";
import "react-floating-whatsapp/dist/index.css";
import logo from "../../images/whiteAvatar.png";

class WhatsAppBtn extends React.Component {
    render(){
        return (
            <FloatingWhatsApp
            className="floating-whatsapp-chatbox"
            styles={{right: "2rem", bottom: "2rem", textAlign: "left"}}
            phoneNumber="+56935595008"
            accountName="Neokode"
            chatMessage="Cómo podemos ayudarte?"
            allowEsc={true}
            avatar={logo}
            placeholder="Mensaje"
            statusMessage="Bienvenido a nuestro sitio web"
            />
        );
    }
}

export default WhatsAppBtn;
