import React, { Component } from "react";

class Advantage extends Component {
  state = { display: false, id: "", sel: true };

  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    const data = [
      {
        id: "tab3",
        iconVentajas: "check",
        ventajas1: "Disminuye tus costos",
        ventajas2: "Automatiza tus procesos",
        ventajas3: "Mejora la coordinación",
        descVentajas1:
          "Reducir costos operativos y de insumos es otro gran beneficio. Ahorrar en impresión, almacenaje y distribución de información física.",
        descVentajas2:
          "Automatizar flujos de trabajo activando alertas, correos electrónicos, tareas, notificaciones, nuevos formularios o asignar acciones a grupos o individuos sin necesidad de intervención humana.",
        descVentajas3:
          "Conocer el progreso de un objetivo y en qué orden proceder a ejecutar las distintas tareas o acciones. Así toda la organización conocerán qué deben hacer, cuándo y cómo.",
      },


    ];
    const data2 = [
      {
        id: "tab4",
        iconVentajas: "check",
        ventajas1: "Toma de decisiones",
        ventajas2: "Controla tus riesgos",
        ventajas3: "Aumenta la productividad",
        descVentajas1:
          "Al procesar de manera automática los datos, te permite identificar y analizar información en tiempo real para adelantarse a posibles contingencias y potenciales errores.",
        descVentajas2:
          "Obtenemos la información definida por nuestros clientes. Y así controlamos los riesgos de manera sistemática y disminuimos la probabilidad de falla en los procesos del cliente.",
        descVentajas3:
          "Dejar la captura de datos manual para realizarla con la ayuda de la tecnología ha demostrado un aumento del 20% de la productividad en los procesos de rutina de las compañías.",
      },
    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="container">
              {data.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div class="cta-inner-content">
                        <div className="ventajas-title">
                          <h2>Ventajas del servicio</h2>
                          <div className="bar"></div>
                        </div>
                        <div class="container">
                          <div className="row">
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas1}</h3>
                                <p>{item.descVentajas1}</p>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas2}</h3>
                                <p>{item.descVentajas2}</p>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas3}</h3>
                                <p>{item.descVentajas3}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center justify-content-center pt-3">
                            <div className="container">
                              {data2.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    id={item.id}
                                    className="tabs_item"
                                  >
                                    <div className="row align-items-center">
                                      <div class="cta-inner-content">
                                        <div class="container">
                                          <div className="row">
                                            <div class="col-lg-4 col-md-6">
                                              <div class="single-cta-box">
                                                <i
                                                  className={
                                                    "fas fa-" +
                                                    item.iconVentajas
                                                  }
                                                ></i>
                                                <h3>{item.ventajas1}</h3>
                                                <p>{item.descVentajas1}</p>
                                              </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                              <div class="single-cta-box">
                                                <i
                                                  className={
                                                    "fas fa-" +
                                                    item.iconVentajas
                                                  }
                                                ></i>
                                                <h3>{item.ventajas2}</h3>
                                                <p>{item.descVentajas2}</p>
                                              </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                              <div class="single-cta-box">
                                                <i
                                                  className={
                                                    "fas fa-" +
                                                    item.iconVentajas
                                                  }
                                                ></i>
                                                <h3>{item.ventajas3}</h3>
                                                <p>{item.descVentajas3}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Advantage;
