import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

class Features extends Component {
  state = { display: false, id: "", sel: true, isOpen: false };

  componentDidMount() {
    this.setState({ display: true });
  }
  openModal = () => {
    this.setState({ isOpen: true });
  };

  render() {
    const data = [
      {
        id: "tab3",
        icon: "list-alt",
        name: "CATÁLOGOS",
        url: require("../../../images/laptopIot.png"),
        iconCarac: "check",
        carac1: "Integración de dispositivos",
        carac2: "Interfaz de usuario intuitiva",
        carac3: "Alertas",
        carac4: "Análisis de datos avanzado",
        descCarac1:
          "Integra con una amplia variedad de dispositivos y sensores, que te permitirá monitorear y controlar tus operaciones desde un solo lugar.",
        descCarac2:
          "Contamos con una interfaz de usuario fácil de usar e intuitiva, que permite la configuración, la monitorización y la gestión de dispositivos de manera eficiente.",
        descCarac3:
          "Configura y recibe alertas de datos fuera de rango para evitar problemas, comienza a prevenir en lugar de corregir.",
        descCarac4:
          "Nuestras herramientas de análisis de datos avanzadas te permiten obtener información valiosa y en tiempo real sobre tus operaciones y procesos.",
      },
    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="saas-section-title">
            <h2>Características del servicio</h2>
            <div className="bar"></div>
          </div>
          <div className="tab  new-features-update-tab  row align-items-center justify-content-center ptb-100 pt-0">
            <div className="container">
              {data.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                          <Link href="#">
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                this.openModal();
                              }}
                              className="video-btn popup-youtube"
                            >
                              <img src={item.url} alt="img" />
                            </a>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac3}</h3>
                                </div>
                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac}
                                    ></i>
                                  </div>
                                  <h3>{item.carac4}</h3>
                                </div>
                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="QyDvr7tfdeU"
          onClose={() => this.setState({ isOpen: false })}/> */}
      </section>
    );
  }
}

export default Features;
