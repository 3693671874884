import React, { Component } from 'react';

class ShopOnline extends Component {
    render() {
        return (
            <section className="shop-online-area ptb-100">
                <div className="container">
                    <div className="payment-processing-section-title">
                        <span>Emprendedores y negocios de barrio</span>
                        <h2>Contrata tu PACK EMPRENDIMIENTO</h2>
                        <p>Te queremos apoyar en tu emprendimiento con la mejor tecnología del mundo.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="shop-online-image">
                                <img src={require("../../../images/pack-emprendedor.png")} alt="shop" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="shop-online-image">
                                <img src={require("../../../images/oportunity.png")} alt="shop" />
                                <h3>Desde <b>2 UF mensual</b></h3>
                                <p>+ Lector código de barra $20.000</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-item">
                                <i className="fas fa-store-alt"></i>
                                <h3>Punto de Venta</h3>
                                <p>Agiliza tus ventas con lector de código de barra y boleta electrónica del SII.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-item">
                                <i className="fas fa-box-open"></i>
                                <h3>Inventario</h3>
                                <p>Controla el stock de tu bodega de forma fácil y rápida.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="single-item">
                                <i className="fas fa-file-invoice"></i>
                                <h3>Contabilidad</h3>
                                <p>Contabiliza tus operaciones automáticamente.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ShopOnline;
