import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Detail extends Component {
    state = {
      html: '',
      isLoading: true,
      isError: false,
      a: -1,
      b: -1
    }
    componentDidMount() {
      if (this.props.location && this.props.location.state) {
        this.setState({...this.props.location.state, isLoading: true});
        this.scrap(this.props.location.state.url);
      } else {
        const params = new URLSearchParams(this.props.location.search);
        const saas = params.get('saas');
        const a = params.get('a');
        const b = params.get('b');
        if (saas && a && b) {
          this.getSaaS(saas, a, b);
        } else {
          this.setState({isError: true, isLoading: false});
        }
      }
    }
    getSaaS = async (key, saas, item) => {
      await fetch('/docs/' + key + '.json')
        .then(async (response) => {
          const res = await response.json();
          if (saas < res.docs.length) {
            const i1 = res.docs[saas];
            if (item < i1.list.length) {
              const doc = i1.list[item];
              this.setState({...doc, saas: key, a: saas, b: item, back: {title: res.title, url: key}});
              this.scrap(doc.url);
            }
          }
          return res;
        })
        .catch((response) => {
          this.setState({isError: true, isLoading: false});
        });
    }
    scrap = async (url) => {
      const res = await fetch(url)
        .then(function (response) {
          return response.text();
        })
        .catch((response) => {
          this.setState({isError: true});
        });
      var html;
      if (!res.startsWith("<article")) {
          html = '<article class="doc-body">' + res + '</article>';
      } else {
          html = res;
      }
      this.setState({html: html, isLoading: false});
    }
    copyToClipboard = (event) => {
      var copyText = document.getElementById("share_link");
      copyText.select();
      copyText.setSelectionRange(0, 99999)
      document.execCommand("copy");
    }
    renderCopy() {
      const url_share = window.location + "?saas=" + this.state.saas + "&a=" + this.state.a + "&b=" + this.state.b;
      return (
        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Copia este link y compártelo</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <input id="share_link" type="text" className="form-control" placeholder="URL" aria-label="URL" aria-describedby="basic-addon1" value={url_share} readOnly/>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.copyToClipboard}>Copiar y Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    render() {
        const {title, description, back, isLoading, isError} = this.state;
        return (
            <React.Fragment>
                <div id="docs" className="doc-container container">
                    <div className="d-table jumbotron">
                        <div className="d-table-cell">
                            <div className="container">
                                <div style={{textAlign: 'right'}}>
                                </div>
                                <div className="about-banner-content align-items-center">
                                    <h2>{ title ? title : "Documentación" }</h2>
                                    <p>
                                      {description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav aria-label="breadcrumb">
                      <div className="share-doc" data-toggle="modal" data-target="#exampleModal">
                        <span>Compartir</span>
                      </div>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"/docs"}>Inicio</Link></li>
                        {
                          !isLoading && back &&
                          <li className="breadcrumb-item"><Link to={"/docs/saas?saas=" + back.url} style={{display: 'inline'}}>{back.title}</Link></li>
                        }
                        <li className="breadcrumb-item active" aria-current="page">{title}</li>
                      </ol>
                    </nav>
                    {
                      isLoading ?
                      <div className="loading">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <span className="box">&nbsp;&nbsp;Cargando contenido...</span>
                      </div>
                      :
                      (
                        isError ?
                        <div className="text-center">
                          <div className="alert alert-danger text-center">
                            No podemos mostrar la documentación solicitada en este momento, intenta más tarde o pídela a <a href="mailto:contecto@neokode.cl">contecto@neokode.cl</a>
                          </div>
                          <Link to="/docs" className="btn btn-primary">Ir a documentación</Link>
                        </div>
                        :
                        <div dangerouslySetInnerHTML={{__html: this.state.html}} />
                      )
                    }
                </div>
                {this.renderCopy()}
            </React.Fragment>
        );
    }
}

export default Detail;
