import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='fas fa-angle-left'></i>",
        "<i class='fas fa-angle-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        768: {
            items:3,
        },
        1024: {
            items: 4,
        },
        1200: {
            items:6,
        }
    }
}

class Partner extends Component {

    state = { display:false};

    componentDidMount(){
        this.setState({ display: true })
    }

    render() {
        const partners = [
          require("../../../images/erp-toyota.png"),
          require("../../../images/erp-hyundai.png"),
          require("../../../images/erp-dabone.png"),
          require("../../../images/black-logo.png"),
          require("../../../images/erp-ibm.png"),
          require("../../../images/erp-bata.png")
        ]
        return (
            <section className="partner-area-four">
                <div className="container">
                    <h3>Preferido por millones de usuarios</h3>

                    <div className="row">
                        {this.state.display ? <OwlCarousel
                            className="partner-slides owl-carousel owl-theme"
                            {...options}
                        >
                            {
                              partners.map((partner, index) => {
                                return (
                                  <div key={index} className="col-lg-12 col-md-12">
                                      <div className="partner-item">
                                          <img src={partner} alt="logo" style={{cursor: 'pointer'}}/>
                                      </div>
                                  </div>
                                );
                              })
                            }
                        </OwlCarousel> : ''}
                    </div>
                </div>
            </section>
        );
    }
}

export default Partner;
