import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

class Features extends Component {
  state = { display: false, id: "", sel: true, isOpen: false };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  openTabSection = (evt, tabNmae) => {
    console.log(tabNmae);
    this.setState({ id: tabNmae, sel: false });
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeInUp");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeInUp animated";
    evt.currentTarget.className += "current";
  };

  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    let { id, sel } = this.state;
    const data = [
      {
        id: "tab1",
        icon: "laptop-code",
        name: "WEB EMPRESARIAL",
        url: require("../../../images/laptopWorkmail.jpg"),
        iconCarac1: "hdd",
        iconCarac2: "lock",
        iconCarac3: "at",
        iconCarac4: "mobile-alt",
        carac1: "Gran capacidad de almacenamiento",
        carac2: "Protección ante spam y virus",
        carac3: "Compatible con Microsoft Outlook",
        carac4: "Sincronizable con IOS y Android",
        descCarac1:
          "Los usuarios no tienen que preocuparse de borrar correos para tener espacio libre, cada casilla cuenta con 50 GB de almacenamiento",
        descCarac2:
          "Análisis constante de spam, malware y virus para proteger a los usuarios de correo electrónico malicioso.",
        descCarac3:
          "Compatibilidad nativa tanto para Windows como para Mac OS X, sin necesidad de instalar software adicional.",
        descCarac4:
          "Conecta tus dispositivos móviles automáticamente o en pocos pasos. Compatible con las principales apps. ",
        sel: this.state.sel,
      },
    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="saas-section-title">
            <h2>Características del servicio</h2>
            <div className="bar"></div>
            {/* <p>Ten la posibilidad de obtener acceso de forma sencilla a tus emails, contactos y calendarios.</p> */}
          </div>
          <div className="tab  new-features-update-tab  row align-items-center justify-content-center">
            <div className="container">
              {data.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                          <Link href="#">
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                this.openModal();
                              }}
                              className="video-btn popup-youtube"
                            >
                              <img src={item.url} alt="img" />
                            </a>
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac1}
                                    ></i>
                                  </div>
                                  <h3>{item.carac1}</h3>
                                </div>
                                <p>{item.descCarac1}</p>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac2}
                                    ></i>
                                  </div>
                                  <h3>{item.carac2}</h3>
                                </div>
                                <p>{item.descCarac2}</p>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac3}
                                    ></i>
                                  </div>

                                  <h3>{item.carac3}</h3>
                                </div>

                                <p>{item.descCarac3}</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="single-inner-content">
                                <div className="title">
                                  <div className="icon">
                                    <i
                                      className={"fas fa-" + item.iconCarac4}
                                    ></i>
                                  </div>

                                  <h3>{item.carac4}</h3>
                                </div>

                                <p>{item.descCarac4}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="3Wq-ghtB0XQ"
          onClose={() => this.setState({ isOpen: false })}
        />
      </section>
    );
  }
}

export default Features;
