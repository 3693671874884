import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

class Faq extends Component {
  render() {
    return (
      <section className="faq-area ptb-100 pt-0">
        <div className="container">
          <div className="saas-section-title">
            <h2>Preguntas frecuentes</h2>
            <div className="bar"></div>
            <p>
              Si tienes alguna duda que no aparece en esta lista con gusto te
              responderemos a trav&eacute;s de nuestro formulario de contacto,
              correo electr&oacute;nico o redes sociales.
            </p>
          </div>

          <div className="faq">
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>&iquest;Es segura?</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    El servicio utiliza los m&aacute;s altos est&aacute;ndares
                    de seguridad provistos por Amazon Web Services, la comunicaci&oacute;n est&aacute;n cifrada con
                    certificado digital.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    &iquest;Qu&eacute; necesito para utilizar la
                    aplicaci&oacute;n?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    La aplicaci&oacute;n es 100% web por lo que s&oacute;lo
                    necesitas un navegador web compatible con Javascript como
                    Chrome, Safari, Firefox, Opera, Microsoft Edge, entre otros.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    &iquest;En cuánto tiempo tendr&eacute; listo mi producto?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Nuestro est&aacute;ndar de entrega, con el correcto flujo de responsabilidades cliente proveedor es de 15 d&iacute;as.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    &iquest;Existen l&iacute;mites de uso?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    No, la aplicaci&oacute;n est&aacute; desarrollada con
                    tecnolog&iacute;a Serverless que le permite adaptar los
                    recusos a la demanda.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;
