import React, { Component } from 'react';
import Navbar from '../components/Layouts/NavbarFour';
import Footer from '../components/Layouts/FooterTwo';

class Terms extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
              <Navbar />
              <section className="Terms ptb-100 mt-5">
                <div className="container">
                  <h1>Términos y Condiciones NEOKODE</h1>
                  <p>
                    Los presentes términos y condiciones de uso regularán la relación del cliente, en adelante el "Suscriptor",
                    respecto del uso de servicios y soluciones tecnológicas que contrate respectivamente con la empresa Servicios Neokode Limitada,
                    que para todos los efectos legales, se entenderá como "Neokode". El cliente al suscribir alguno de los servicios
                    proporcionados por Neokode, acepta estos "Términos y Condiciones" y con esto se dará por iniciada la relación comercial
                    entre ambas partes. Neokode podrá cambiar o modificar los "Términos y Condiciones" de uso; dicho cambio deberá ser informado
                    oportunamente por Neokode a todos sus clientes y serán publicados en el sitio Web, www.neokode.cl. Al contratar cualquiera
                    de los servicios de Neokode, el Suscriptor debe leer cuidadosamente estos "Términos y Condiciones", ya que, para todos los
                    efectos legales y por el mero uso de alguno de los servicios, el Suscriptor acepta y reconoce que ha revisado y que está de
                    acuerdo con los "Términos y Condiciones" de uso, incluyendo las eximentes de responsabilidad que se mencionan a continuación.
                    Si el cliente no estuviese de acuerdo con los "Términos y Condiciones" o con cualquier modificación que se le haya informado,
                    éste no podrá utilizar los servicios ofrecidos por Neokode.
                  </p>
                  <ol>
                    <li>
                      <b>Uso de los servicios de Neokode:</b> El suscriptor tiene expresamente prohibido utilizar los servicios de Neokode
                      de manera inadecuada entendiéndose como tal: Exponer información confidencial de la aplicación o vulnerar los derechos
                      de propiedad; utilizarlas para publicar y/o difundir contenido inapropiado que vulnere la integridad de quienes utilicen
                      los servicios o cualquier contenido que viole o infrinja alguna ley de contenido vigente, sin importar si el afectado
                      de este contenido publicado es usuario del servicio o es una persona externa al vínculo contractual. El suscriptor sólo
                      podrá utilizar los servicios en la medida que la ley permita. Neokode podrá suspender o cancelar los servicios contratados,
                      si el suscriptor no cumple con las políticas o condiciones o si se sospecha que el servicio o solución tecnológica puede
                      ser destinada para fines malintencionados. El uso de los servicios de Neokode no le convierte en titular de ninguno de los
                      derechos de propiedad intelectual de los mismos ni del contenido al que accedas. Solo podrá usar el contenido de los servicios
                      si es autorizado por escrito en papel y firmado ante notario o con la firma electrónica si es que existiese por algún
                      representante de alto rango de Neokode. En relación con el uso de los servicios y soluciones tecnológicas, Neokode podrá
                      enviar avisos de servicio, mensajes administrativos y otro tipo de información. Algunos de los servicios de Neokode están
                      disponibles en dispositivos móviles y otros únicamente para plataforma de escritorio. Neokode solicita NO utilizar los
                      servicios en momentos en que pueda distraerlo y que le impida cumplir las leyes de tráfico o de seguridad pudiendo generar
                      un accidente producto de la utilización de estos.
                    </li>
                    <li>
                      <b>Derechos de propiedad entre el suscriptor y Neokode:</b> Servicios Neokode Limitada es dueño único y exclusivo de todos
                      los derechos, título e intereses de las aplicaciones disponibles en nuestro sitio web www.neokode.cl, de todo el contenido
                      creado y entregado incluyendo: audio, fotografías, ilustraciones, gráficos, otros medios visuales, videos, copias, textos,
                      software, títulos, archivos, etc., de los códigos, datos y materiales del mismo, el aspecto y el ambiente, el diseño y la
                      organización del Sitio Web, los servicios de tecnología disponibles y la compilación de los contenidos, códigos, datos y
                      los materiales, incluyendo los derechos de autor, derechos de marca, derechos de patente, derechos de base de datos,
                      derechos morales, otras propiedades intelectuales y derechos patrimoniales del mismo. En resumen el uso de las aplicaciones
                      de Neokode no le otorga propiedad alguna al suscriptor de ninguno de los contenidos, códigos, datos o materiales a los que
                      pueda acceder a través de la aplicación contratada.
                    </li>
                    <li>
                      <b>Uso prohibido:</b> Cualquier distribución, publicación o explotación comercial o promocional de los servicios de Neokode o
                      de la aplicación, o de cualquiera de los contenidos, códigos, datos o materiales en el Sitio Web, está estrictamente prohibida,
                      a menos de que usted haya recibido el previo permiso expreso por escrito del personal autorizado por Neokode o de algún otro
                      poseedor de derechos aplicable según lo indicado en el punto número uno (1). De no estar autorizado, el suscriptor no puede
                      descargar, informar, exponer, publicar, copiar, reproducir, distribuir, transmitir, modificar, ejecutar, difundir, transferir,
                      crear trabajos derivados de, vender o de cualquier otra manera explotar cualquiera de los contenidos, códigos, datos o
                      materiales en o disponibles a través del Sitio Web u otro medio de comercialización autorizado por Neokode. Prohíbe y repudia
                      cualquier acto de pedofilia o pornografía infantil y en la misma línea cualquier acto que ponga en riesgo los Derechos Humanos
                      de algún ciudadano sea chileno o extranjero, por lo que si se detecta la utilización de los servicios o aplicaciones entregadas
                      por Neokode, el suscriptor será puesto de manera inmediata en manos de la justicia chilena vía denuncia expresa de Neokode.
                    </li>
                    <li>
                      <b>Protección de la privacidad:</b> Para que el suscriptor pueda usar los servicios de Neokode de manera correcta y eficiente,
                      se debe recopilar una serie de información:
                      <ol type="a">
                        <li>
                          Información que el suscriptor proporciona de forma voluntaria a la aplicación.
                        </li>
                        <li>
                          Información automática que el suscriptor entrega al interactuar con los servicios en donde estos son registrados
                          para poder utilizar las aplicaciones.
                        </li>
                      </ol>
                      Neokode no declara ni garantiza que los servicios ofrecidos permanezcan libres de pérdidas, deterioro, ataques, virus,
                      interferencias, piratería u otros tipos de brechas de seguridad, en consecuencia niega toda responsabilidad relacionada
                      con lo anteriormente mencionado.
                    </li>
                    <li>
                      <b>Cómo se utiliza la información:</b> Neokode utiliza la información para operar, proporcionar y mejorar las ofertas
                      de los servicios para la disponibilidad del suscriptor.
                      <ol type="a">
                        <li>
                          <b>Personalización del servicio:</b> Neokode utiliza la información personal del suscriptor para recomendar ofertas que podrían
                          ser del interés de este último, identificando las preferencias y personalizando la experiencia con los servicios entregados.
                        </li>
                        <li>
                          <b>Comunicación con el Suscriptor:</b> Neokode utiliza la información personal para poder comunicarse con el suscriptor
                          en relación con los servicios por medio de diferentes canales (Ej. por teléfono, correo electrónico, chat).
                        </li>
                        <li>
                          <b>Fines para los que Neokode solicita consentimiento:</b> Neokode podrá solicitar el consentimiento del suscriptor
                          para utilizar información personal para un fin específico que se le será comunicado debidamente al suscriptor.
                        </li>
                        <li>
                          <b>Cookies:</b> Para que los sistemas de Neokode reconozcan el navegador o dispositivo del suscriptor y le proporcionen
                          los datos guardados, se utilizan cookies. La información sobre los suscriptores de Neokode es una parte importante del
                          surgimiento del negocio y en consecuencia Neokode protege de cualquier tipo de venta o tráfico la información personal
                          de los propios suscriptores a otros suscriptores o externos.
                        </li>
                        <li>
                          <b>Cómo Neokode protege la información:</b> En Neokode la seguridad es la mayor prioridad. Se diseñan los servicios
                          teniendo en mente la seguridad y privacidad del suscriptor, utilizando la mayor cantidad de recursos disponibles a
                          nivel digital para evitar cualquier violación a la información proporcionada.
                        </li>
                        <li>
                          <b>Acceso y opción:</b> El suscriptor puede ver, actualizar y borrar ciertos tipos de información sobre la cuenta
                          personal y las interacciones con los servicios de Neokode. Si el suscriptor no puede acceder o actualizar información
                          el suscriptor, siempre puede contactarse con un ejecutivo de Neokode vía web o telefónica para obtener asistencia
                          desde el área de soporte. El suscriptor puede optar por no proporcionar ciertos tipos de información, pero entonces
                          es posible que no pueda acceder a servicios que lo requieran.
                        </li>
                        <li>
                          <b>Conservación de información personal:</b> Neokode conserva la información personal de los suscriptores para permitir
                          el uso continuado de los servicios durante el tiempo que se requiera para cumplir con los fines correspondientes
                          descritos en estos "Términos y Condiciones", según pueda ser requerido por la ley (incluyendo para fines fiscales
                          y de contabilidad) o tal y como se le pueda comunicar de otro modo al suscriptor. La cantidad de tiempo durante la
                          cual Neokode conservará la información del suscriptor variará dependiendo de la finalidad del uso de esta, y se
                          borrará la información personal conforme a la legislación aplicable lo indique.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Información del usuario:</b> El suscriptor reconoce y acepta ser el único responsable de la exactitud del contenido
                      de la "Información del Usuario" creado. Para proteger la confidencialidad de la información, el suscriptor es responsable
                      del uso de su contraseña para acceder a los servicios de Neokode. Se recomienda que cuando el suscriptor reciba la primera
                      contraseña genérica de acceso a los servicios, cambie los datos de acceso y evite entregarlos a terceras personas.
                    </li>
                    <li>
                      <b>Información recibida:</b> A menos que se solicite específicamente, Neokode no solicitará ninguna información
                      confidencial, secreta o patrimonial, ni otro material del suscriptor, a través de la web, por correo electrónico
                      o de cualquier otra manera. Cualquier información, trabajos creativos, demostración, ideas, sugerencias, conceptos,
                      métodos, sistemas, diseños, planes, técnicas u otros materiales que el suscriptor haya enviado o presentado
                      (incluyendo, por ejemplo y sin limitación, aquello que el suscriptor presenta o envía a nuestros grupos de trabajo,
                      soporte, canales de comunicación, etc. o que nos envíe vía correo electrónico) será información considerada como no
                      confidencial o secreto y que puede ser usada por Neokode de cualquier manera para mejorar nuestros servicios.
                    </li>
                    <li>
                      <b>Suscripción:</b> El suscriptor, al momento de contratar cualquiera de los servicios de Neokode, se compromete a
                      cumplir con los debidos pagos y los plazos asociados a estos. Neokode proveerá al suscriptor de una plataforma de pagos
                      que se encargará de realizar los cobros de los servicios, en forma periódica. Neokode le proporcionará al suscriptor un
                      panel de administración simple y con todos los materiales necesarios para el manejo y administración de sus servicios.
                      El suscriptor puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento. En
                      caso de consultas se sugiere los siguientes canales de contacto: sitio web www.neokode.cl o correo: contacto@neokode.cl.
                    </li>
                    <li>
                      <b>Modificación y eliminación de servicios:</b> Es posible que se modifiquen o eliminen algunos servicios comercializados
                      por Neokode (características, precios, funcionalidades, etc.). Para tales efectos Neokode se compromete a dar aviso a sus
                      clientes con 30 días de anticipación cualquiera sea el cambio en su oferta de servicios, por medio de su Sitio Web,
                      www.neokode.cl o por medio de un correo electrónico que se enviará a todos los clientes involucrados en dichos cambios.
                      De igual modo, si el suscriptor decidiera eliminar o dejar de utilizar cualquiera de los servicios contratados y deberá
                      haber pagado el 100% de lo adeudado al momento de dar de baja el servicio. Neokode considera que el suscriptor es el
                      propietario de sus datos, por lo mismo, el cliente será responsable de extraer la información antes de dar la baja
                      definitiva del servicio.
                    </li>
                    <li>
                      <b>Leyes aplicables:</b> Las personas que escojan acceder a los servicios de Neokode desde otros lugares lo harán por
                      su propia iniciativa y son responsables del cumplimiento de las leyes vigentes en la República de Chile o cualquiera
                      sea la ubicación. Neokode no se hace responsable de la vulneración de leyes tanto nacionales como pactos internacionales
                      correspondiente al lugar geográfico donde se utilicen nuestros servicios.
                    </li>
                    <li>
                      <b>Término de la suscripción:</b> Neokode puede restringir, suspender o terminar el acceso de un suscriptor a los servicios.
                      si se cree que el suscriptor está en incumplimiento de alguno de los puntos de los términos y condiciones o de la legislación
                      vigente y aplicable o por cualquier otra razón sin notificación o responsabilidad. Neokode mantiene una política que estipula
                      la terminación, en circunstancias apropiadas, de los privilegios de uso de los servicios para suscriptores que son violadores
                      repetitivos de los derechos de propiedad intelectual.
                    </li>
                    <li>
                      <b>Cambios de Términos de Uso:</b> Neokode se reserva el derecho, a su sola discreción, de cambiar, modificar, añadir o quitar
                      cualquier porción de los Términos y Condiciones, toda o en parte, en cualquier momento. Los cambios en los Términos y
                      Condiciones serán efectivos cuando se publiquen. La continuación del uso del Sitio Web y/o de los servicios puestos a
                      disposición en o a través del Sitio Web después de haber sido publicado cualquier cambio, será considerado como aceptación
                      de esos cambios por lo que invitamos a revisar constantemente este documento.
                    </li>
                    <li>
                      <b>Garantías y renuncias de responsabilidad:</b> Neokode ofrece sus servicios con un nivel de competencia y diligencia
                      razonable desde el punto de vista comercial, y se espera que cada suscriptor disfrute al utilizarlos. No obstante Neokode Ltda.
                      no puede ofrecer garantías en relación con algunos aspectos de nuestros Servicios. Ni Neokode ni sus proveedores o distribuidores
                      ofrecen garantías específicas sobre los servicios distintas a las establecidas de forma expresa en estas condiciones o en las
                      condiciones adicionales. Neokode excluye todas las garantías.
                    </li>
                  </ol>
                  <p style={{textAlign: 'center'}}>Consulta precios de nuestros servicios en: <a href="https://www.neokode.cl" target="_blank" rel="noopener noreferrer">www.neokode.cl</a></p>
                  <p style={{textAlign: 'center'}}><b>Estos Términos y Condiciones fueron actualizados el 30 de Julio de 2024</b></p>
                </div>
              </section>
              <Footer />
            </React.Fragment>
        );
    }
}

export default Terms;
