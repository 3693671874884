import React, { Component } from "react";
import Navbar from "../../components/Layouts/NavbarThree";
import Footer from "../../components/Layouts/FooterTwo";
import Banner from "../../components/Service/Mail/Banner";
import Advantage from "../../components/Service/Mail/Advantage";
import Faq from "../../components/Service/Mail/Faq";
import Pricing from "../../components/Service/Mail/Pricing";
import Contact from "../../components/Service/Mail/Contact";
import Features from "../../components/Service/Mail/Features";

class MailApp extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Banner />
        <section class="separator-area">&nbsp;</section>
        <Features />
        <section class="separator-area">&nbsp;</section>
        <Advantage />
        <section class="separator-area">&nbsp;</section>
        <Pricing />
        <section class="separator-area">&nbsp;</section>
        <Faq />
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

export default MailApp;
