import React, { Component } from "react";
import { Link } from "react-router-dom";

class Pricing extends Component {
  render() {
    return (
      <section id="pricing" className="pricing-area ptb-100 pt-0">
        <div className="container">
          <div className="saas-section-title">
            <h2>Nuestros precios</h2>
            <div className="bar"></div>
            <p>El pago es de acuerdo al uso.</p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">&nbsp;</div>
            <div className="col-lg-4 col-md-6">
              <div className="pricing-table active">
                <div className="pricing-header">
                  <div className="icon">
                    <img
                      src={require("../../../images/icon.png")}
                      alt="icon"
                    />
                  </div>
                </div>

                <ul className="pricing-features">
                  <li className="active">Plantillas ilimitadas</li>
                  <li className="active">Checklist ilimitados</li>
                  <li className="active">Almacenamiento ilimitado</li>
                  <li className="active">Usuarios ilimitados</li>
                  <li className="active">Alertas</li>
                  <li className="active">Soporte</li>
                  <li className="active">Reportes mensuales</li>
                  <li className="active">Analytics</li>
                </ul>

                <div className="price">
                  Desde 1.5 UF + IVA
                  <span>Mensual</span>
                </div>
                <div className="price">
                  1000 checklist iniciados 0,3 UF + IVA
                  <span>Mensual</span>
                </div>

                <Link to="/contact" className="btn btn-primary">
                  Consultar
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">&nbsp;</div>
          </div>
        </div>
      </section>
    );
  }
}

export default Pricing;
