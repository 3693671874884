import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Data from './Data';

class Pricing extends Component {
  render() {
    return (
      <section id="pricing" className="pricing-area ptb-100 pt-0">
        <div className="container">
          <div className="saas-section-title">
            <h2>Nuestros precios</h2>
            <div className="bar"></div>
            <p>El pago por servicio mensual de acuerdo al uso.</p>
            <ul style={{ listStyle: 'none' }}>
              <li>Implementación <b>10 UF</b> + IVA</li>
              <li>Certificaci&oacute;n factura electr&oacute;nica <b>2.5 UF</b> + IVA</li>
              <li>Actualizaciones <b>GRATIS</b></li>
              <li><b>Sin cobros sorpresas</b></li>
              <li><b>Sin contrato</b></li>
            </ul>
          </div>
          <div class="row">
            {Data.pricing.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-6">
                  <div className={"pricing-table " + item.active}>
                    <div className="pricing-header">
                      <div className="icon">
                        <img src={require("../../../images/icon.png")} alt="icon" />
                      </div>
                      <h3>{item.title}</h3>
                    </div>
                    <ul className="pricing-features">
                      {item.pricing_features.map((item, index) => {
                        return (
                          item.li !== "&nbsp;" ?
                            <li className="active">{item.li}</li>
                            :
                            <li className="active">&nbsp;</li>
                        );
                      })}
                    </ul>
                    <div className="price">
                      {item.price}
                      <span>Mensual</span>
                    </div>
                    <Link to="/contact" className="btn btn-primary">Consultar</Link>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="table-erp pt-5">
            <div className="saas-section-title">
              <h2>Facturación electrónica</h2>
              <div className="bar"></div>
              <ul style={{ listStyle: 'none' }}>
                <li>Certificación <b>2.5 UF</b> + IVA (Una vez)</li>
                <li>Valores mensuales según documentos</li>
              </ul>
            </div>
            <div className="row pb-5 justify-content-center">
              <div className="col-lg-6">
                    <div className="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="text-center" scope="col">Desde</th>
                            <th class="text-center" scope="col">Hasta</th>
                            <th class="text-center" scope="col">Precio UF + IVA</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Data.table.map((item, index) => {
                            return (
                              <tr class="inner-box">
                                <td>
                                  <div class="table-item d-flex justify-content-center">
                                    <div class="meta">
                                      <div class="time">
                                        <span>{item.desde}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="table-item d-flex justify-content-center">
                                    <div class="meta">
                                      <div class="time">
                                        <span>{item.hasta}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="table-item d-flex justify-content-center">
                                    <div class="meta">
                                      <div class="time">
                                        <span>{item.precio}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Pricing;
