import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";

const options = {
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='fas fa-angle-left'></i>",
    "<i class='fas fa-angle-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};
class Advantage extends Component {
  state = { display: false, id: "", sel: true };

  openTabSection = (evt, tabNmae) => {
    console.log(tabNmae);
    this.setState({ id: tabNmae, sel: false });
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeInUp");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeInUp animated";
    evt.currentTarget.className += "current";
  };

  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    let { id, sel } = this.state
    const data = [
      {
        id: "tab1",
        ventajasTilte: "VENTAJAS DEL SERVICIO",
        iconVentajas: "check",
        ventajas1: "Bajo costo",
        ventajas2: "Servicio administrado",
        ventajas3: "Seguro",
        descVentajas1:
          "Los precios de nuestro servicio de email empresarial son muy económicos considerando la relación precio/calidad.",
        descVentajas2:
          "Administra tus casillas sin necesidad de aprovisionar servidores de correo en tus instalaciones físicas.",
        descVentajas3:
          "Se cifran todos los datos en reposo de manera automática mediante claves de cifrado seguras.",
        sel: this.state.sel,
      },
    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="tab  new-features-update-tab  row align-items-center justify-content-center">
            <div className="container">
              {data.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div class="cta-inner-content">
                        <div className="ventajas-title">
                          <h2>Ventajas del servicio</h2>
                          <div className="bar"></div>
                          {/* <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> */}
                        </div>
                        <div class="container">
                          <div className="row">
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i className={"fas fa-" + item.iconVentajas}></i>
                                <h3>{item.ventajas1}</h3>
                                <p>{item.descVentajas1}</p>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i className={"fas fa-" + item.iconVentajas}></i>
                                <h3>{item.ventajas2}</h3>
                                <p>{item.descVentajas2}</p>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i className={"fas fa-" + item.iconVentajas}></i>
                                <h3>{item.ventajas3}</h3>
                                <p>{item.descVentajas3}</p>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Advantage;
