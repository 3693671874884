import React, { Component } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";

class Banner extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="home" className="payment-processing-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12 p-0">
                <div className="payment-processing-banner-content">
                  <h1>Servicio Email empresarial</h1>
                  <p>
                    Correo electrónico y calendario empresarial seguro y administrado con tu dominio @tudominio.cl
                  </p>
                  <div className="btn-box">
                  <a href="#pricing" className="btn btn-primary">
                    Ver los precios
                    </a>
                    <Link to="/contact">
                      <a className="btn btn-primary">Contrata ahora</a>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 p-0">
                <div className="payment-processing-banner-image mail">
                  <img
                    src={require("../../../images/banMail.png")}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Banner;
