import React, { Component } from "react";

class Advantage extends Component {
  state = { display: false, id: "", sel: true };

  componentDidMount() {
    this.setState({ display: true });
  }
  render() {
    const data = [
      {
        id: "tab1",
        icon: "laptop-code",
        name: "WEB EMPRESARIAL",
        url: require("../../../images/pizarro.jpg"),
        iconCarac: "check",
        ventajasTilte: "VENTAJAS DE UNA PÁGINA WEB",
        iconVentajas: "check",
        ventajas1: "Imagen corporativa",
        ventajas2: "Posiciona tu marca",
        ventajas3: "Fideliza a todos tus clientes",
        descVentajas1:
          "Es la vitrina de tu negocio para tus clientes.",
        descVentajas2:
          "Te permite estar siempre visible para tus clientes de forma digital.",
        descVentajas3:
          "Te permite generar confianza y crea un lazo con ellos.",
        sel: this.state.sel,
      },
    ];
    const data2 = [
      {
        id: "tab2",
        icon: "newspaper",
        name: "CMS",
        url: require("../../../images/propyme.jpg"),
        iconCarac: "check",
        ventajasTilte: "VENTAJAS DE UN CMS",
        iconVentajas: "check",
        ventajas1: "Edición de contenido",
        ventajas2: "Seguro",
        ventajas3: "Todo en tiempo real",
        descVentajas1:
          "Te permite administrar fácilmente tu web sin conocimientos previos.",
        descVentajas2:
          "Nosotros nos preocupamos de mantener tu sitio seguro y actualizado.",
        descVentajas3:
          "No pierdas tiempo, el futuro es ahora.",
      },
    ];
    return (
      <section id="services" className="services-area ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="container">
              {data.map((item, index) => {
                return (
                  <div key={index} id={item.id} className="tabs_item">
                    <div className="row align-items-center">
                      <div class="cta-inner-content">
                        <div className="ventajas-title">
                          <h2>Ventajas del servicio</h2>
                          <div className="bar"></div>
                        </div>
                        <div class="container">
                          <div className="row">
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas1}</h3>
                                <p>{item.descVentajas1}</p>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas2}</h3>
                                <p>{item.descVentajas2}</p>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <div class="single-cta-box">
                                <i
                                  className={"fas fa-" + item.iconVentajas}
                                ></i>
                                <h3>{item.ventajas3}</h3>
                                <p>{item.descVentajas3}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center justify-content-center pt-3">
                            <div className="container">
                              {data2.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    id={item.id}
                                    className="tabs_item"
                                  >
                                    <div className="row align-items-center">
                                      <div class="cta-inner-content">
                                        <div class="container">
                                          <div className="row">
                                            <div class="col-lg-4 col-md-6">
                                              <div class="single-cta-box">
                                                <i
                                                  className={
                                                    "fas fa-" +
                                                    item.iconVentajas
                                                  }
                                                ></i>
                                                <h3>{item.ventajas1}</h3>
                                                <p>{item.descVentajas1}</p>
                                              </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                              <div class="single-cta-box">
                                                <i
                                                  className={
                                                    "fas fa-" +
                                                    item.iconVentajas
                                                  }
                                                ></i>
                                                <h3>{item.ventajas2}</h3>
                                                <p>{item.descVentajas2}</p>
                                              </div>
                                            </div>
                                            <div class="col-lg-4 col-md-6">
                                              <div class="single-cta-box">
                                                <i
                                                  className={
                                                    "fas fa-" +
                                                    item.iconVentajas
                                                  }
                                                ></i>
                                                <h3>{item.ventajas3}</h3>
                                                <p>{item.descVentajas3}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Advantage;
