import React, { Component } from "react";
import Navbar from "../../components/Layouts/NavbarThree";
import Footer from "../../components/Layouts/FooterTwo";
import Banner from "../../components/Service/Checklist/Banner";
import Services from "../../components/Service/Checklist/Services";
import Features from "../../components/Service/Checklist/Features";
import HowWorks from "../../components/Service/Checklist/HowWorks";
import Advantage from "../../components/Service/Checklist/Advantage";
import Contact from "../../components/Service/Checklist/Contact";
import Pricing from "../../components/Service/Checklist/Pricing";
import Faq from "../../components/Service/Checklist/Faq";
/*
import Funfact from '../components/Saas/Funfact';
import NewFeatures from '../components/Saas/NewFeatures';
import Feedback from '../components/Saas/Feedback';
import Trial from '../components/Saas/Trial';
import Blog from '../components/Saas/Blog';
import Partner from '../components/Saas/Partner';
import Subscribe from '../components/Saas/Subscribe';
import Contact from '../components/Saas/Contact';
*/

class ChecklistApp extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Banner />
        <section class="separator-area">&nbsp;</section>
        <Features />
        <section class="separator-area">&nbsp;</section>
        <Advantage />
        <section class="separator-area">&nbsp;</section>
        {/* <Features /> */}
        {/* <section class="separator-area">&nbsp;</section>
        <HowWorks /> */}
        <Pricing />
        <section class="separator-area">&nbsp;</section>
        <Faq />
        {/*
                <Trial />
                <Blog />
                <Partner />
                */}
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

export default ChecklistApp;
