import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      isSuccess: false,
      name: "",
      email: "",
      phone: "",
      subject: "",
      subjectList: [
        { name: "Consulta general" },
        { name: "Quiero ser cliente" },
        { name: "Servicio ERP APP" },
        { name: "Servicio WEB APP" },
        { name: "Servicio E-COMMERCE APP" },
        { name: "Servicio MAIL APP" },
        { name: "Servicio CHECKLIST APP" },
        { name: "Servicio FLEET APP" },
        { name: "Servicio IOT APP" },
        { name: "Servicio DOMAIN APP" },
        { name: "Servicio TRATAMIENTO DE AVERÍAS APP" },
      ],
      message: "",
      key: "",
      isErrorName: false,
      isErrorEmail: false,
      isErrorPhone: false,
      isErrorSubject: false,
      isErrorMessage: false,
      isErrorCaptcha: false,
    };
  }
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });

  };
  validate() {
    var errors = {};
    errors.isErrorName = this.state.name.length < 3;
    errors.isErrorEmail = this.state.email.length < 8;
    errors.isErrorPhone = this.state.phone.length < 8;
    errors.isErrorSubject = this.state.subject.length < 3;
    errors.isErrorMessage = this.state.message.length < 3;
    errors.isErrorCaptcha = this.state.key.length < 64;
    errors.hasError =
      errors.isErrorName ||
      errors.isErrorEmail ||
      errors.isErrorPhone ||
      errors.isErrorSubject ||
      errors.isErrorMessage ||
      errors.isErrorCaptcha;
    return errors;
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const errors = this.validate();
    if (errors.hasError) {
      errors.hasError = undefined;
      this.setState({ ...errors });
      return;
    }
    this.setState({ ...errors, isLoading: true });
    try {
      const response = await this.contact({
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        message: this.state.message,
        key: this.state.key,
      });
      if (response.code === 0) {
        this.setState({
          isLoading: false,
          isError: false,
          isSuccess: true,
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });
      } else {
        this.setState({ isLoading: false, isError: true, isSuccess: false });
      }
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false, isError: true, isSuccess: false });
    }
  };
  contact(data) {
    return fetch(config.url.api + "/contact", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      res.json();
    });
  }
  handleClose = (event) => {
    this.setState({ isErrorMessage: false });
  };
  handleChangeRC = (value) => {
    this.setState({ key: value, isEmailError: false, isKeyError: false });
  };
  render() {
    return (
      <section id="contact" className="contact-area ptb-100 bg-f9f9f9">
        <div className="container">
          {!this.props.noTitle && (
            <div className="section-title">
              <h2>Contacto</h2>
            </div>
          )}

          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="contact-info-box">
                <div className="row text-center">
                  <div className="col-md-6">
                    <i className="fas fa-envelope"></i>
                    <span>Email:</span>
                    <a href="mailto:contacto@neokode.cl">contacto@neokode.cl</a>
                  </div>
                    <div className="col-md-6">
                      <i className="fab fa-whatsapp"></i>
                      <span>WhatsApp:</span>
                      <a href="https://api.whatsapp.com/send?phone=56935595008" target="_blanck">
                        +56 9 3559 5008
                      </a>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <form id="contactForm" onSubmit={this.handleSubmit}>
                <div className="row justify-content-md-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <input
                        id="name"
                        value={this.state.name}
                        placeholder="Nombre"
                        type="text"
                        className={
                          "form-control" +
                          (this.state.isErrorName ? " is-invalid" : "")
                        }
                        required={true}
                        data-error="Ingresa tu nombre"
                        onChange={this.handleChange}
                      />
                      <div className="help-block with-errors">
                        {this.state.isErrorName && (
                          <div className="invalid-feedback">
                            Debes ingresar tu nombre
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-md-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <input
                        id="email"
                        value={this.state.email}
                        placeholder="Email"
                        type="email"
                        className={
                          "form-control" +
                          (this.state.isErrorEmail ? " is-invalid" : "")
                        }
                        required={true}
                        data-error="Ingresa tu email"
                        onChange={this.handleChange}
                      />
                      <div className="help-block with-errors">
                        {this.state.isErrorEmail && (
                          <div className="invalid-feedback">
                            Debes ingresar tu email
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-md-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <select
                        id="subject"
                        onChange={this.handleChange}
                        required={true}
                        value={this.state.subject}
                        className={"form-control"}
                      >
                        <option value="" disabled selected hidden>
                          Seleccione un asunto...
                        </option>
                        {this.state.subjectList.map((x, index) => {
                          return (
                            <option value={x.name} disabled={false}>
                              {x.name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="help-block with-errors">
                        {this.state.isErrorSubject && (
                          <div className="invalid-feedback">
                            Debes ingresar el asunto
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row justify-content-md-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <input
                        id="subject"
                        value={this.state.subject}
                        placeholder="Asunto"
                        type="text"
                        className={
                          "form-control" +
                          (this.state.isErrorSubject ? " is-invalid" : "")
                        }
                        required={true}
                        data-error="Ingresa el asunto"
                        onChange={this.handleChange}
                      />
                      <div className="help-block with-errors">
                        {this.state.isErrorSubject && (
                          <div className="invalid-feedback">
                            Debes ingresar el asunto
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row justify-content-md-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <input
                        id="phone"
                        value={this.state.phone}
                        placeholder="Tel&eacute;fono"
                        type="text"
                        className={
                          "form-control" +
                          (this.state.isErrorPhone ? " is-invalid" : "")
                        }
                        required={true}
                        data-error="Ingresa tu teléfono"
                        onChange={this.handleChange}
                      />
                      <div className="help-block with-errors">
                        {this.state.isErrorPhone && (
                          <div className="invalid-feedback">
                            Debes ingresar tu tel&eacute;fono
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-md-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <textarea
                        id="message"
                        placeholder="Mensaje"
                        value={this.state.message}
                        className={
                          "form-control" +
                          (this.state.isErrorMessage ? " is-invalid" : "")
                        }
                        cols="30"
                        rows="4"
                        required={true}
                        data-error="Escribe el mensaje"
                        onChange={this.handleChange}
                      />
                      <div className="help-block with-errors">
                        {this.state.isErrorMessage && (
                          <div className="invalid-feedback">
                            Debes ingresar el mensaje
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-md-center">
                  {(this.state.isError || this.state.isSuccess) && (
                    <div
                      className={
                        "alert alert-" +
                        (this.state.isError ? "danger" : "success") +
                        " col-lg-12 col-md-12"
                      }
                      role="alert"
                    >
                      {this.state.isError
                        ? "Error al enviar el mensaje, por favor intenta nuevamente."
                        : "Mensaje enviado exitosamente. Nos comunicaremos contigo prontamente."}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}

                  <div className="col-lg-6 col-md-6">
                    <div className="row">
                      <div className="col-md-6">
                        <ReCAPTCHA
                          sitekey={config.ReCAPTCHA}
                          onChange={this.handleChangeRC}
                          className="recaptcha"
                        />
                        <div className="help-block with-errors">
                          {this.state.isErrorCaptcha && (
                            <div className="invalid-feedback">
                              Debes completar captcha
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 contact-submit">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={this.state.isLoading}
                        >
                          {this.state.isLoading
                            ? "Enviando..."
                            : "Enviar Mensaje"}
                        </button>
                        <div
                          id="msgSubmit"
                          className="h3 text-center hidden"
                        ></div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
