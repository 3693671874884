import React, { Component } from "react";
import { Link } from "react-router-dom";

class Pricing extends Component {
  render() {
    return (
      <section id="pricing" className="pricing-area ptb-100 pt-0">
        <div className="container">
          <div className="saas-section-title">
            <h2>Nuestros precios</h2>
            <div className="bar"></div>
            <p>El pago por servicio mensual de acuerdo al uso.</p>
            <ul style={{ listStyle: "none" }}>
              <li>
                Implementación <b>10 UF</b> + IVA
              </li>
              <li>
                Certificaci&oacute;n factura electr&oacute;nica <b>2.5 UF</b> +
                IVA
              </li>
              <li>
                Actualizaciones <b>GRATIS</b>
              </li>
              <li>
                <b>Sin cobros sorpresas</b>
              </li>
              <li>
                <b>Sin contrato</b>
              </li>
            </ul>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="pricing-table active">
                <div className="pricing-header">
                  <div className="icon">
                    <img
                      src={require("../../../images/icon1.png")}
                      alt="icon"
                    />
                  </div>
                  <h3>Plan Emprende</h3>
                </div>

                <ul className="pricing-features">
                  <li className="active">Hasta 3 usuarios</li>
                  <li className="active">Ventas</li>
                  <li className="active">Compras</li>
                  <li className="active">Inventario</li>
                  <li className="active">Gastos</li>
                  <li className="active">Contabilidad</li>
                  <li className="active">Cobranza</li>
                  <li className="active">Punto de venta</li>
                  <li className="active">Alertas</li>
                  <li className="active">Reportes</li>
                  <li className="active">Soporte</li>
                  <li className="active">Facturación electrónica</li>
                  <li className="active">&nbsp;</li>
                  <li className="active">&nbsp;</li>
                  <li className="active">&nbsp;</li>
                  <li className="active">&nbsp;</li>
                </ul>

                <div className="price">
                  Desde 3 UF + IVA
                  <span>Mensual</span>
                </div>
                <Link to="/contact" className="btn btn-primary">
                  Consultar
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pricing-table active">
                <div className="pricing-header">
                  <div className="icon">
                    <img
                      src={require("../../../images/icon1.png")}
                      alt="icon"
                    />
                  </div>
                  <h3>Plan PYME</h3>
                </div>

                <ul className="pricing-features">
                  <li className="active">Hasta 7 usuarios</li>
                  <li className="active">CRM</li>
                  <li className="active">Ventas</li>
                  <li className="active">Compras</li>
                  <li className="active">Inventario</li>
                  <li className="active">Gastos</li>
                  <li className="active">Contabilidad</li>
                  <li className="active">Cobranza</li>
                  <li className="active">Punto de venta</li>
                  <li className="active">Alertas</li>
                  <li className="active">Reportes</li>
                  <li className="active">Soporte</li>
                  <li className="active">Facturación electrónica</li>
                  <li className="active">Módulos personalizados</li>
                  <li className="active">&nbsp;</li>
                  <li className="active">&nbsp;</li>
                </ul>

                <div className="price">
                  Desde 7 UF + IVA
                  <span>Mensual</span>
                </div>
                <Link to="/contact" className="btn btn-primary">
                  Consultar
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pricing-table active">
                <div className="pricing-header">
                  <div className="icon">
                    <img
                      src={require("../../../images/icon2.png")}
                      alt="icon"
                    />
                  </div>

                  <h3>Plan Estándar</h3>
                </div>

                <ul className="pricing-features">
                  <li className="active">Hasta 12 usuarios</li>
                  <li className="active">CRM</li>
                  <li className="active">Ventas</li>
                  <li className="active">Compras</li>
                  <li className="active">Inventario</li>
                  <li className="active">Gastos</li>
                  <li className="active">Contabilidad</li>
                  <li className="active">Cobranza</li>
                  <li className="active">Punto de venta</li>
                  <li className="active">RRHH y remuneraciones</li>
                  <li className="active">Manufactura</li>
                  <li className="active">Alertas</li>
                  <li className="active">Reportes</li>
                  <li className="active">Soporte</li>
                  <li className="active">Facturación electrónica</li>
                  <li className="active">Módulos personalizados</li>
                </ul>

                <div className="price">
                  Desde 12 UF + IVA
                  <span>Mensual</span>
                </div>
                <Link to="/contact" className="btn btn-primary">
                  Consultar
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="pricing-table active">
                <div className="pricing-header">
                  <div className="icon">
                    <img
                      src={require("../../../images/icon2.png")}
                      alt="icon"
                    />
                  </div>

                  <h3>Plan Premium</h3>
                </div>

                <ul className="pricing-features">
                  <li className="active">Desde 20 usuarios</li>
                  <li className="active">CRM</li>
                  <li className="active">Ventas</li>
                  <li className="active">Compras</li>
                  <li className="active">Inventario</li>
                  <li className="active">Gastos</li>
                  <li className="active">Contabilidad</li>
                  <li className="active">Cobranza</li>
                  <li className="active">Punto de venta</li>
                  <li className="active">RRHH y remuneraciones</li>
                  <li className="active">Manufactura</li>
                  <li className="active">Alertas</li>
                  <li className="active">Reportes</li>
                  <li className="active">Soporte</li>
                  <li className="active">Facturación electrónica</li>
                  <li className="active">Módulos personalizados</li>
                </ul>

                <div className="price">
                  Desde 20 UF + IVA
                  <span>Mensual</span>
                </div>
                <Link to="/contact" className="btn btn-primary">
                  Consultar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Pricing;
