export default {
  feature1: [
    {
      id: "tab1",
      icon: "laptop-code",
      name: "WEB EMPRESARIAL",
      url: require("../../../images/pizarro.jpg"),
      iconCarac: "check",
      carac1: "Intuitiva",
      carac2: "Página web responsiva",
      carac3: "Alto rendimiento",
      carac4: "Certificado de seguridad",
      descCarac1:
        "Es perfecta para entregar información de manera clara y precisa.",
      descCarac2:
        "Nuestros diseños son adaptables a los diferentes tipos de dispositivos.",
      descCarac3:
        "Nuestra infraestructura está preparada para entregar contenido con baja latencia y alta velocidad",
      descCarac4:
        "Toda transacción o movimiento realizado en el sitio web cuentan con protección de datos y su certificado de seguridad SSL.",
      ventajasTilte: "VENTAJAS DE UNA PÁGINA WEB",

      iconVentajas: "check",
      ventajas1: "Imagen corporativa",
      ventajas2: "Posiciona tu marca",
      ventajas3: "Fideliza a todos tus clientes",
      descVentajas1:
        "Tener una página web te permitirá plasmar todos tus contenidos en ella y todo lo que quieras reflejar. El diseño de tu web, le dará a tus futuros clientes una imagen de cómo es la marca.",
      descVentajas2:
        "Contar con un espacio digital, es fundamental hoy en día. Por eso, tener tu página web te dará una posición de marca y un reconocimiento tanto a nivel local como nacional.",
      descVentajas3:
        "Mediante tu página web, podrás conocer los gustos de tus clientes y saber qué es lo que quieren y que no. Así, podrás ofrecerles lo que buscan, consiguiendo que sean fiel a tu marca.",


    },
  ],

  feature2: [
    {
      id: "tab2",
      icon: "newspaper",
      name: "CMS",
      url: require("../../../images/propyme.jpg"),
      iconCarac: "check",
      carac1: "Gestión de contenido",
      carac2: "Gestión de usuario",
      carac3: "Fácil a la hora de aprender",
      carac4: "Alta disponibilidad",
      descCarac1:
        "Incluimos las herramientas necesarias para editar, organizar, revisar y publicar tus contenidos.",
      descCarac2:
        "Gestiona los roles de cada usuario, para la modificación de contenido en el CMS.",
      descCarac3:
        "Nuestra interfaz es clara y facilita la gestión y el mantenimiento de contenido.",
      descCarac4:
        "Nuestra tecnología asegura la entrega del contenido en todo momento, con una tasa de 99.99%",
      ventajasTilte: "VENTAJAS DE UN CMS",
      iconVentajas: "check",
      ventajas1: "Edición de contenido",
      ventajas2: "Seguridad",
      ventajas3: "Cambios en tiempo real",
      ventajas4: "Certificado de Seguridad",
      descVentajas1:
        "Usuarios que no estén capacitados técnicamente pueden agregar, formatear y editar su contenido en el sitio web sin tener que modificar el diseño y la codificación.",
      descVentajas2:
        "Nos aseguramos de que todas las funciones de seguridad integradas del CMS estén actualizadas y sean capaces de anticipar todos los tipos de malware u otros tipos de ataques.",
      descVentajas3:
        "Al editar contenido, este podrá estar disponible para cualquier usuario de Internet desde el momento de su publicación. Esto posibilita realizar actualizaciones de forma instantánea.",
      descVentajas4:
        "Toda transaccion o movimiento realizado en el sitio web cuentan con protección de datos y su certificado de seguridad ssl.",
    },
  ],
}