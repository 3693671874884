import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import App from './App';
import unregisterServiceWorker from './serviceWorker';
import config from './config';

import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';

export default createBrowserHistory({});

ReactGA.initialize(config.ga_analytics)

const hist = createBrowserHistory();
hist.listen(location => {
	ReactGA.set({ page: location.pathname })
  ReactGA.send({ hitType: "pageview", page: location.pathname});
});

ReactDOM.render(
  <Router history={hist}>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregisterServiceWorker();
