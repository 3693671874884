import React, { Component } from 'react';

class Separator extends Component {

    render() {
        const className = this.props.className;
        return (
            <section className={className ? className : "separator-area"}>
                &nbsp;
            </section>
        );
    }
}

export default Separator;
