import React from 'react'
import Navbar from '../components/Layouts/NavbarFour';
import Footer from '../components/Layouts/FooterTwo';
import Index from '../components/Documentation/Index';

class Documentacion extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Index />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Documentacion;
