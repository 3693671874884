import React, { Component } from "react";

import Navbar from "../../components/Layouts/NavbarThree";
import Footer from "../../components/Layouts/FooterTwo";
import Banner from "../../components/Service/Erp/Banner";
import Partner from "../../components/Service/Erp/Partner";
import Features from "../../components/Service/Erp/Features";
import Cta from "../../components/Service/Erp/Cta";
import HowWorks from "../../components/Service/Erp/HowWorks";
import ShopOnline from "../../components/Service/Erp/ShopOnline";
import Faq from "../../components/Service/Erp/Faq";
import Pricing from "../../components/Service/Erp/Pricing";
import Contact from "../../components/Service/Erp/Contact";

class ErpApp extends Component {
  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Banner />
        <section class="separator-area">&nbsp;</section>
        <Cta />
        <section class="separator-area">&nbsp;</section>
        <Features />
        <section class="separator-area">&nbsp;</section>
        <HowWorks />
        <section class="separator-area">&nbsp;</section>
        <Pricing />
        {/* <section class="separator-area">&nbsp;</section> */}
        {/* <Faq /> */}
        {/* <section class="separator-area">&nbsp;</section> */}
        {/* <Partner />
                <section class="separator-area">&nbsp;</section> */}
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

export default ErpApp;
