import React, { Component } from 'react';

import Navbar from '../../components/Layouts/NavbarThree';
import Footer from '../../components/Layouts/FooterTwo';

//Servicio Web Simple
import BannerWeb from '../../components/Service/Web/Banner';
import Advantage from '../../components/Service/Web/Advantage';
import Features from '../../components/Service/Web/Features';
import Pricing from '../../components/Service/Web/Pricing';
import Faq from '../../components/Service/Web/Faq';
import Contact from '../../components/Service/Web/Contact';

class WebApp extends Component {
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <BannerWeb />
                <section class="separator-area">&nbsp;</section>
                <Features/>
                <section class="separator-area">&nbsp;</section>
                <Advantage/>
                <section class="separator-area">&nbsp;</section>
                <Pricing />
                <section class="separator-area">&nbsp;</section>
                <Faq/>
                <Contact/>
                <Footer />
            </React.Fragment>
        );
    }
}

export default WebApp;
